
import { useEffect } from 'react';

export function ScrollHandler() {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);

    // Set document height immediately to avoid layout shifts
    document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    
    // Update height on resize
    const updateHeight = () => {
      document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    
    window.addEventListener('resize', updateHeight);

    // Handle hash links
    if (window.location.hash) {
      // Use a small timeout to ensure everything is loaded
      setTimeout(() => {
        const id = window.location.hash.substring(1);
        const element = document.getElementById(id);
        if (element) {
          // Use scrollIntoView with a smooth behavior
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }

    // Smooth scroll for anchor links
    const handleAnchorClick = function(e: Event) {
      const target = e.currentTarget as HTMLAnchorElement;
      if (!target) return;
      
      // Don't intercept links with download attribute or target="_blank"
      if (target.hasAttribute('download') || target.getAttribute('target') === '_blank') {
        return;
      }
      
      e.preventDefault();
      
      const href = target.getAttribute('href');
      if (href === "#") return;
      
      const targetElement = document.querySelector(href as string);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: "start"
        });
        
        // Update URL hash without page jump
        if (history.pushState) {
          history.pushState(null, '', href);
        }
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    return () => {
      window.removeEventListener('resize', updateHeight);
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);

  return null;
}
