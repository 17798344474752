
import { useEffect } from 'react';

interface StructuredDataProps {
  pageType: 'homepage' | 'about' | 'contact';
}

export function StructuredData({ pageType = 'homepage' }: StructuredDataProps) {
  useEffect(() => {
    // Enhanced structured data with more detailed information based on SEO recommendations
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "ProfessionalService",
      "name": "NEX2GO Solution Center",
      "description": "Professionelle IT-Dienstleistungen, Webhosting, Domainregistrierung und digitale Transformation für Unternehmen. Maßgeschneiderte Lösungen für Ihren digitalen Erfolg.",
      "url": "https://nex2go.de",
      "logo": "https://www.nex2go.de/lovable-uploads/054c03f1-20b1-40e4-a9f5-00362154f2c0.png",
      "sameAs": [
        "https://www.facebook.com/nex2go",
        "https://www.linkedin.com/company/nex2go",
        "https://twitter.com/nex2go"
      ],
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "DE",
        "addressLocality": "Deutschland"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.1657",
        "longitude": "10.4515"
      },
      "priceRange": "€€",
      "telephone": "+49",
      "email": "info@nex2go.de",
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday", 
          "Tuesday", 
          "Wednesday", 
          "Thursday", 
          "Friday"
        ],
        "opens": "09:00",
        "closes": "17:00"
      },
      //"image": {
      //  "@type": "ImageObject",
      //  "url": "https://nex2go.de/header.jpg",
      //  "width": "1920",
      //  "height": "1080",
      //  "caption": "NEX2GO Solution Center - IT-Lösungen & Digitale Transformation"
      //},
      "foundingDate": "2005",
      "keywords": "IT-Lösungen, Webhosting, Digitale Transformation, Unternehmensberatung, Domainregistrierung, Serverlösungen, IT-Support",
      "areaServed": {
        "@type": "Country",
        "name": "Deutschland"
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://nex2go.de"
      },
      "offers": {
        "@type": "AggregateOffer",
        "offers": [
          {
            "@type": "Offer",
            "name": "IT-Lösungen",
            "description": "Maßgeschneiderte IT-Lösungen für Unternehmen"
          },
          {
            "@type": "Offer",
            "name": "Webhosting",
            "description": "Professionelle Webhosting-Dienste"
          },
          {
            "@type": "Offer",
            "name": "Digitale Transformation",
            "description": "Strategische Beratung zur digitalen Transformation"
          }
        ]
      }
    };

    // Additional structured data for BreadcrumbList
    const breadcrumbSchema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://nex2go.de/"
        }
      ]
    };

    // Create script tag for structured data
    let scriptElement = document.querySelector('script[type="application/ld+json"]');
    if (!scriptElement) {
      scriptElement = document.createElement('script');
      scriptElement.setAttribute('type', 'application/ld+json');
      document.head.appendChild(scriptElement);
    }
    scriptElement.textContent = JSON.stringify(structuredData);

    // Create script tag for breadcrumb schema
    let breadcrumbScriptElement = document.getElementById('breadcrumb-schema');
    if (!breadcrumbScriptElement) {
      breadcrumbScriptElement = document.createElement('script');
      breadcrumbScriptElement.id = 'breadcrumb-schema';
      breadcrumbScriptElement.setAttribute('type', 'application/ld+json');
      document.head.appendChild(breadcrumbScriptElement);
    }
    breadcrumbScriptElement.textContent = JSON.stringify(breadcrumbSchema);

    return () => {
      // Cleanup
      if (scriptElement && scriptElement.parentNode) {
        scriptElement.parentNode.removeChild(scriptElement);
      }
      if (breadcrumbScriptElement && breadcrumbScriptElement.parentNode) {
        breadcrumbScriptElement.parentNode.removeChild(breadcrumbScriptElement);
      }
    };
  }, [pageType]);

  return null;
}
