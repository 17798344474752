
import { Container } from "@/components/ui/Container";
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import { File, MapPin, Phone, Mail, Globe, Info, Scale } from "lucide-react";
import { useEffect } from "react";
import '@/index.css';

export default function Impressum() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <Container size="narrow">
          <div className="bg-card dark:bg-nex-900 shadow-md rounded-lg p-8 border border-border animate-fade-in">
            <h1 className="text-2xl md:text-3xl font-bold mb-8 pb-4 border-b border-border dark:border-nex-800 text-red-800 dark:text-red-600">
              Impressum
            </h1>
            
            <section className="mb-8 animate-slide-up [animation-delay:100ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <File className="mr-2 h-5 w-5" />
                Angaben gemäß § 5 TMG
              </h2>
              <p className="font-semibold">NEX2GO Solution Center</p>
              <p>Waldemar Wetzel</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:200ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <MapPin className="mr-2 h-5 w-5" />
                Postanschrift
              </h2>
              <p>Hochfeldring 92/2</p>
              <p>76549 Hügelsheim</p>
              <p>Deutschland</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:300ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Info className="mr-2 h-5 w-5" />
                Kontakt
              </h2>
              <p className="flex items-center mb-2">
                <Phone className="mr-2 h-4 w-4 text-muted-foreground" />
                Telefon: +49 1520 58 666 90
              </p>
              <p className="flex items-center mb-2">
                <Mail className="mr-2 h-4 w-4 text-muted-foreground" />
                E-Mail: <a href="mailto:wetzel@nex2go.de" className="text-red-800 dark:text-red-600 hover:underline ml-1">wetzel@nex2go.de</a>
              </p>
              <p className="flex items-center">
                <Globe className="mr-2 h-4 w-4 text-muted-foreground" />
                Website: <a href="https://www.nex2go.de" className="text-red-800 dark:text-red-600 hover:underline ml-1" target="_blank" rel="noopener noreferrer">www.nex2go.de</a>
              </p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:400ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Scale className="mr-2 h-5 w-5" />
                Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV
              </h2>
              <p>Waldemar Wetzel</p>
              <p>Hochfeldring 92/2</p>
              <p>76549 Hügelsheim</p>
              <p>Deutschland</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:500ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                Hinweis zur Umsatzsteuer
              </h2>
              <p>Als Kleinunternehmer im Sinne von § 19 UStG wird keine Umsatzsteuer berechnet.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:600ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                EU-Streitschlichtung
              </h2>
              <p className="mb-2">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</p>
              <p><a href="https://ec.europa.eu/consumers/odr" className="text-red-800 dark:text-red-600 hover:underline" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a></p>
              <p className="mt-2">Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
            </section>

            <section className="animate-slide-up [animation-delay:700ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                Verbraucherstreitbeilegung/Universalschlichtungsstelle
              </h2>
              <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>
            </section>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
