
/**
 * Generic Error Page Component
 * Used for rendering different HTTP error states
 */

import React from "react";
import { Button } from "@/components/ui/button";
import { Container } from "@/components/ui/Container";
import { Home, ArrowLeft } from "lucide-react";

interface ErrorPageProps {
  code: string;
  title: string;
  description: string;
  icon?: React.ReactNode;
}

/**
 * Generic error page component that can be customized for different error types
 */
const ErrorPage: React.FC<ErrorPageProps> = ({ 
  code, 
  title, 
  description, 
  icon 
}) => {
  /**
   * Handle navigation back to previous page
   */
  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * Handle navigation to home page
   */
  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#f9f9f9] to-[#f1f1f1]">
      <Container size="narrow" className="py-12 md:py-20">
        <div className="relative overflow-hidden bg-white p-8 md:p-12 rounded-xl shadow-lg text-center max-w-2xl mx-auto border border-red-800/10">
          {/* Top border accent */}
          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-red-700 via-red-800 to-red-900"></div>
          
          {/* Error icon */}
          <div className="mb-8 flex justify-center">
            {icon || (
              <div className="h-24 w-24 rounded-full bg-red-50 flex items-center justify-center">
                <div className="h-12 w-12 text-red-800">
                  {/* Default error icon */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                </div>
              </div>
            )}
          </div>
          
          {/* Error code */}
          {code && (
            <span className="inline-block text-6xl md:text-7xl font-bold mb-6 text-red-800 heading-highlight">
              {code}
            </span>
          )}
          
          {/* Error title */}
          <h1 className="text-2xl md:text-3xl font-bold mb-6 text-[#1A1F2C]">{title}</h1>
          
          {/* Error description */}
          <p className="text-gray-600 mb-10 max-w-md mx-auto">
            {description}
          </p>
          
          {/* Action buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button 
              onClick={handleGoBack}
              variant="outline"
              className="gap-2 border-red-200 text-red-900 hover:bg-red-50 hover:text-red-950 transition-all duration-300"
            >
              <ArrowLeft className="h-4 w-4" />
              Zurück
            </Button>
            
            <Button 
              onClick={handleGoHome}
              className="gap-2 bg-gradient-to-r from-red-700 to-red-900 hover:from-red-800 hover:to-red-950 text-white transition-all duration-300"
            >
              <Home className="h-4 w-4" />
              Startseite
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ErrorPage;
