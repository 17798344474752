
/**
 * Vite Module Handler
 * 
 * Specialized module for handling Vite-specific module patterns and dependencies
 */

import { PRIMARY_CDN } from '../cdnConfig';
import { markAssetAsLoaded } from './assetTracker';

/**
 * Special handling for Vite-specific module patterns
 */
export function patchViteModules(): void {
  // Special handling for Vite's __vite__mapDeps pattern
  Array.from(document.scripts).forEach(script => {
    if (script.textContent && (
      script.textContent.includes('__vite__mapDeps') || 
      script.textContent.includes('from "./vendor.js"') ||
      script.textContent.includes('assets/js/')
    )) {
      let content = script.textContent;

      // Handle the specific pattern for Vite's module mapper
      if (content.includes('const __vite__mapDeps = (i, m=__vite__mapDeps, d=(m.f || (m.f = [')) {
        content = content.replace(
          /const __vite__mapDeps = \(i, m=__vite__mapDeps, d=\(m\.f \|\| \(m\.f = \[(["'])([^"']+)(["'])/g,
          (match, q1, path, q3) => {
            // Replace paths in array with CDN paths
            return match.replace(
              /(["'])assets\/js\/([^"']+)\.js(["'])/g, 
              `$1${PRIMARY_CDN}/assets/js/$2.js$3`
            );
          }
        );

        // Replace import statements
        content = content.replace(
          /from ["']\.\/vendor\.js["']/g, 
          `from "${PRIMARY_CDN}/assets/js/vendor.js"`
        );
        
        // Also handle relative imports to other modules
        content = content.replace(
          /from ["']\.\/([\w-]+)\.js["']/g, 
          `from "${PRIMARY_CDN}/assets/js/$1.js"`
        );

        // Replace the script with updated content
        if (content !== script.textContent) {
          const newScript = document.createElement('script');
          newScript.textContent = content;
          if (script.type) newScript.type = script.type;
          if (script.src) newScript.src = script.src;
          // Add identifier to prevent duplicate processing
          newScript.setAttribute('data-vite-patched', 'true');
          script.parentNode?.replaceChild(newScript, script);
          markAssetAsLoaded(script.src || 'inline-script', 'patchViteModules-replace');
        }
      }
    }
  });

  // Look for global Vite objects to patch
  if (typeof window !== 'undefined') {
    Object.keys(window).forEach(key => {
      // This targets specifically Vite's module map objects
      if (key.startsWith('__') && (window as any)[key] && typeof (window as any)[key] === 'object') {
        const obj = (window as any)[key];
        
        // Check for the f property which often contains module paths
        if (obj.f && Array.isArray(obj.f)) {
          obj.f = obj.f.map((path: string) => {
            if (typeof path === 'string') {
              // For paths that look like assets/js/X.js
              if (path.match(/^assets\/js\/.*\.js$/)) {
                return `${PRIMARY_CDN}/${path}`;
              }
            }
            return path;
          });
          console.log(`Patched Vite module paths in global object ${key}`);
        }
        
        // Also check for methods that might return paths
        if (typeof obj === 'function' && obj.toString().includes('assets/js/')) {
          try {
            // Try to replace the function with a patched version
            const originalFunction = obj;
            (window as any)[key] = function(...args: any[]) {
              const result = originalFunction.apply(this, args);
              
              // If result is an array of strings, replace paths
              if (Array.isArray(result) && result.every(item => typeof item === 'string')) {
                return result.map(item => {
                  if (item.match(/^assets\/js\/.*\.js$/)) {
                    return `${PRIMARY_CDN}/${item}`;
                  }
                  return item;
                });
              }
              
              return result;
            };
            console.log(`Patched Vite module function ${key}`);
          } catch (e) {
            console.error(`Failed to patch Vite module function ${key}:`, e);
          }
        }
      }
    });
  }
}
