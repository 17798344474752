
/**
 * CDN-Konfiguration
 * 
 * Zentralisierte Konfiguration für CDN-URLs mit Vermeidung von Duplikaten
 */

import { isAssetLoaded, markAssetAsLoaded, normalizeUrlForTracking } from './modules/assetTracker';

// Verfügbare CDN-URLs
export const CDN_URLS = [
  'https://cdn.nex2go-it.de',
  'https://cdn1.nex2go-it.de',
  'https://cdn2.nex2go-it.de',
  'https://cdn3.nex2go-it.de',
  'https://cdn4.nex2go-it.de'
];

// Hauptsächliche CDN-URL
export const PRIMARY_CDN = CDN_URLS[0];

// Auswahl-Strategie
export enum CDNSelectionStrategy {
  DETERMINISTIC = 'deterministic', // Basierend auf Asset-Pfad (für konsistente Cache-Nutzung)
  RANDOM = 'random'                // Zufällig bei jedem Aufruf
}

// Cache zur Speicherung von Asset-zu-CDN-Zuordnungen für deterministische Strategie
const assetCdnMappingCache = new Map<string, string>();

// Aktuelle Strategie - defaultmäßig deterministisch für Caching-Vorteile
let currentStrategy: CDNSelectionStrategy = CDNSelectionStrategy.DETERMINISTIC;

/**
 * Strategie für die CDN-Auswahl festlegen
 * @param strategy Die zu verwendende Strategie
 */
export const setCDNSelectionStrategy = (strategy: CDNSelectionStrategy): void => {
  // Bei Strategiewechsel Cache leeren, um konsistentes Verhalten zu gewährleisten
  if (strategy !== currentStrategy) {
    assetCdnMappingCache.clear();
  }
  currentStrategy = strategy;
  
  // Hinweis im Konsolenlog für Entwickler
  console.log(`CDN-Strategie auf ${strategy} gesetzt`);
};

/**
 * Aktuell verwendete CDN-Auswahl-Strategie abrufen
 * @returns Die aktuelle Strategie
 */
export const getCDNSelectionStrategy = (): CDNSelectionStrategy => {
  return currentStrategy;
};

/**
 * Wählt eine CDN-URL basierend auf dem eingestellten Algorithmus
 * @param assetPath Der Asset-Pfad zur Bestimmung der CDN-URL (bei deterministischer Strategie)
 * @returns Eine CDN-URL
 */
export const selectCdnUrl = (assetPath: string): string => {
  // Prüfen, ob für diesen Pfad bereits eine CDN-URL ausgewählt wurde
  if (assetCdnMappingCache.has(assetPath)) {
    return assetCdnMappingCache.get(assetPath) as string;
  }
  
  // Bei zufälliger Strategie wähle eine zufällige CDN-URL
  if (currentStrategy === CDNSelectionStrategy.RANDOM) {
    const randomIndex = Math.floor(Math.random() * CDN_URLS.length);
    const selectedUrl = CDN_URLS[randomIndex];
    
    // Auch bei zufälliger Strategie cachen wir die Auswahl, bis die Seite neu geladen wird
    assetCdnMappingCache.set(assetPath, selectedUrl);
    
    return selectedUrl;
  }
  
  // Bei deterministischer Strategie verwende den Hash-Algorithmus
  const cleanPath = assetPath.replace(/-[a-f0-9]{8}\.(js|css|png|jpg|webp)$/i, '.$1')
                            .replace(/\?.*$/, ''); // Entferne Query-Parameter
  const charSum = cleanPath.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const cdnIndex = charSum % CDN_URLS.length;
  const selectedUrl = CDN_URLS[cdnIndex];
  
  // Ergebnis für zukünftige Anfragen cachen
  assetCdnMappingCache.set(assetPath, selectedUrl);
  
  return selectedUrl;
};

/**
 * Normalisiert einen URL-Pfad für Deduplizierungszwecke
 * @param path Der zu normalisierende Pfad
 * @returns Normalisierter Pfad ohne Protokoll, Domain und Query-Parameter
 */
export const normalizeAssetPath = (path: string): string => {
  return normalizeUrlForTracking(path);
};

/**
 * Prüft, ob ein Asset bereits geladen wurde
 * @param path Der zu prüfende Pfad
 * @returns true wenn das Asset bereits geladen wurde, sonst false
 */
export const isAssetAlreadyLoaded = (path: string): boolean => {
  return isAssetLoaded(path);
};

/**
 * Markiert ein Asset als geladen
 * @param path Der Pfad des geladenen Assets
 */
export const markAssetAsAlreadyLoaded = (path: string): void => {
  markAssetAsLoaded(path, 'cdnConfig');
};

/**
 * Konvertiert einen relativen Pfad in einen CDN-Pfad
 * @param path Der zu konvertierende Pfad
 * @returns CDN-URL mit Pfad
 */
export const convertToCDNPath = (path: string): string => {
  if (!path || typeof path !== 'string') return path;
  
  // Wenn bereits eine vollständige URL, nicht umwandeln
  if (path.startsWith('http') || path.startsWith('//')) {
    // Prüfe, ob es eine www.nex2go.de URL ist, die nach CDN umgewandelt werden sollte
    if (path.includes('www.nex2go.de')) {
      return path.replace('www.nex2go.de', PRIMARY_CDN.replace('https://', ''));
    }
    return path;
  }
  
  // Normalisiere den Pfad
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  const result = `${PRIMARY_CDN}${normalizedPath}`;
  
  // Markiere standardmäßig als geladen, um Doppelladungen zu vermeiden
  markAssetAsLoaded(result, 'convertToCDNPath');
  
  return result;
};

// CORS-Header für CDN-Ressourcen
export const CDN_CORS_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, OPTIONS',
  'Access-Control-Allow-Headers': 'Content-Type'
};

// Liste der möglichen CDN-Domains für Überprüfungen
export const CDN_DOMAINS = [
  'cdn.nex2go-it.de', 
  'cdn1.nex2go-it.de', 
  'cdn2.nex2go-it.de', 
  'cdn3.nex2go-it.de', 
  'cdn4.nex2go-it.de'
];

// Konvertiere eine URL von www.nex2go.de zu CDN
export const convertMainDomainToCdn = (url: string): string => {
  if (!url || typeof url !== 'string') return url;
  
  // Ersetze www.nex2go.de mit der primären CDN-Domain
  if (url.includes('www.nex2go.de')) {
    const newUrl = url.replace('www.nex2go.de', PRIMARY_CDN.replace('https://', ''));
    markAssetAsLoaded(newUrl, 'mainToCdn');
    return newUrl;
  }
  
  return url;
};
