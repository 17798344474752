
import { useState, useEffect } from "react";
import { Container } from "./ui/Container";
import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";
import { navLinks } from "./navbar/NavLinks";
import { MobileMenu } from "./navbar/MobileMenu";
import { PRIMARY_CDN } from "@/utils/asset/cdnConfig";

export function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const isHomePage = window.location.pathname === "/" || window.location.pathname === "/index.html";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isHomePage) {
      setIsScrolled(true);
    }
  }, [isHomePage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-500 ease-in-out py-3 bg-white backdrop-blur-md shadow-md ${
        isScrolled ? "py-2" : "py-3 md:py-4"
      }`}
    >
      <Container>
        <nav className="flex items-center justify-between">
          <a href="/" className="text-2xl font-semibold tracking-tight flex items-center z-50 relative">
            <div className="p-1 rounded bg-white">
              <img 
                src={`${PRIMARY_CDN}/assets/images/054c03f1-20b1-40e4-a9f5-00362154f2c0-200w.webp`}
                srcSet={`${PRIMARY_CDN}/assets/images/054c03f1-20b1-40e4-a9f5-00362154f2c0-200w.webp 200w`}
                sizes="200px"
                alt="NEX2GO logo" 
                className={`${isLoaded ? 'transition-all duration-300' : ''}`}
                fetchPriority="high"
                loading="eager"
                width="200"
                height="60"
                style={{ width: '200px', height: '60px', objectFit: 'contain' }}
              />
            </div>
          </a>

          <div className="hidden md:flex gap-8 items-center">
            <ul className="flex space-x-8">
              {navLinks.map((link) => (
                <li key={link.name}>
                  <a
                    href={link.href}
                    className={`text-sm font-medium relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-red-800 hover:text-red-800 text-gray-800 ${isLoaded ? 'transition-all duration-300 after:transition-all after:duration-300 hover:after:w-full' : ''}`}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
            {isHomePage ? (
              <Button 
                className={`bg-red-800 hover:bg-red-700 text-white shadow-md hover:shadow-lg ${isLoaded ? 'transition-all duration-300' : ''}`}
                onClick={() => window.location.href="#contact"}
              >
                Kontakt aufnehmen
              </Button>
            ) : (
              <a href="/#contact">
                <Button 
                  className={`bg-red-800 hover:bg-red-700 text-white shadow-md hover:shadow-lg ${isLoaded ? 'transition-all duration-300' : ''}`}
                >
                  Kontakt aufnehmen
                </Button>
              </a>
            )}
          </div>

          <button
            className={`md:hidden p-3 rounded-full text-gray-800 bg-gray-100 hover:bg-gray-200 z-50 relative ${isLoaded ? 'transition-all duration-300' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
            aria-expanded={mobileMenuOpen}
          >
            {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>

        <MobileMenu 
          isOpen={mobileMenuOpen} 
          isHomePage={isHomePage} 
          onClose={() => setMobileMenuOpen(false)} 
        />
      </Container>
    </header>
  );
}
