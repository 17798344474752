
/**
 * Script Handler Module
 * 
 * Handles script-related functionality for CDN initialization
 */

import { PRIMARY_CDN } from '../cdnConfig';
import { ensureCDNUrl } from '../assetManager';
import { 
  patchDynamicImports, 
  patchImportMeta 
} from './script/dynamicImportPatcher';
import { 
  removeDuplicateScripts, 
  loadScriptWithCdn,
  isScriptLoaded
} from './script/scriptLoader';
import { patchRuntimeModuleLoading, patchVitePreload } from './script/runtimeModulePatcher';

// Export all functions for use by other modules
export {
  patchDynamicImports,
  patchImportMeta,
  patchVitePreload,
  removeDuplicateScripts,
  loadScriptWithCdn,
  patchRuntimeModuleLoading,
  isScriptLoaded
};

/**
 * Combined function to initialize all script patching
 * This is called once at startup to ensure all script loading uses CDN
 */
export const initializeScriptPatchers = (): void => {
  // Patch dynamic imports for import() calls - do this first
  patchDynamicImports();
  
  // Patch import.meta.url handling
  patchImportMeta();
  
  // Patch runtime module loading - this is critical
  patchRuntimeModuleLoading();
  
  // Remove duplicate scripts - do this after patching mechanisms
  removeDuplicateScripts();
  
  // Add CORS attributes to all script tags that use CDN
  addCorsAttributesToCdnScripts();
  
  // Schedule another duplicate removal after a short delay
  // This catches scripts added by other initialization processes
  setTimeout(removeDuplicateScripts, 1000);
  
  // Schedule a final check after all content should be loaded
  setTimeout(removeDuplicateScripts, 3000);
};

/**
 * Adds crossorigin attributes to all script tags that use CDN URLs
 * This ensures proper CORS handling for CDN resources
 */
const addCorsAttributesToCdnScripts = (): void => {
  const scripts = document.querySelectorAll('script');
  scripts.forEach(script => {
    if (script.src && (
      script.src.includes(PRIMARY_CDN) || 
      script.src.includes('cdn.nex2go-it.de') || 
      script.src.includes('cdn1.nex2go-it.de') || 
      script.src.includes('cdn2.nex2go-it.de') || 
      script.src.includes('cdn3.nex2go-it.de') || 
      script.src.includes('cdn4.nex2go-it.de')
    )) {
      // Add crossorigin attribute if it's a CDN script
      if (!script.hasAttribute('crossorigin')) {
        script.setAttribute('crossorigin', 'anonymous');
      }
      
      // Mark as loaded from CDN
      if (!script.hasAttribute('data-cdn-loaded')) {
        script.setAttribute('data-cdn-loaded', 'true');
      }
    }
  });
};

/**
 * Observe the DOM for dynamically added scripts and ensure they use CDN
 * This catches scripts added after the initial page load
 */
export const observeDynamicScriptAdditions = (): void => {
  // Create a MutationObserver to watch for new scripts
  const observer = new MutationObserver((mutations) => {
    let needsDeduplication = false;
    
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element;
            
            // Handle script elements
            if (element.tagName === 'SCRIPT') {
              const script = element as HTMLScriptElement;
              if (script.src && !script.src.includes(PRIMARY_CDN) && !script.src.includes('cdn.nex2go-it.de')) {
                // Convert to CDN URL
                const cdnUrl = ensureCDNUrl(script.src);
                if (cdnUrl !== script.src) {
                  script.src = cdnUrl;
                  script.setAttribute('crossorigin', 'anonymous');
                  script.setAttribute('data-cdn-loaded', 'true');
                  needsDeduplication = true;
                }
              }
            }
          }
        });
      }
    });
    
    // Remove duplicates if we modified any scripts
    if (needsDeduplication) {
      removeDuplicateScripts();
    }
  });
  
  // Start observing the document
  observer.observe(document, { childList: true, subtree: true });
};
