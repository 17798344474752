
import { lazy, Suspense, useEffect, useRef } from "react";
import { MetaTags } from "@/components/seo/MetaTags";
import { StructuredData } from "@/components/seo/StructuredData";
import { ScrollHandler } from "@/components/layout/ScrollHandler";
import { useMultiElementAnimationObserver } from "@/hooks/use-animation-observer";
import { initializeCDNStrategy } from "@/utils/asset/cdnInitializer";

// Lazy-load components to reduce initial bundle size
const Navbar = lazy(() => import("@/components/Navbar").then(module => ({ default: module.Navbar })));
const Hero = lazy(() => import("@/components/Hero").then(module => ({ default: module.Hero })));
const Services = lazy(() => import("@/components/Services").then(module => ({ default: module.Services })));
const Features = lazy(() => import("@/components/Features").then(module => ({ default: module.Features })));
const Contact = lazy(() => import("@/components/Contact").then(module => ({ default: module.Contact })));
const Footer = lazy(() => import("@/components/Footer").then(module => ({ default: module.Footer })));

// Loading component for lazy-loaded sections with exact dimensions
const SectionLoading = () => (
  <div className="flex justify-center items-center py-12 h-[500px] w-full" aria-hidden="true">
    <div className="loading-spinner" style={{ width: '40px', height: '40px' }}></div>
  </div>
);

// Footer loading component - smaller height
const FooterLoading = () => (
  <div className="h-[300px] bg-red-800 w-full flex items-center justify-center" aria-hidden="true">
    <div className="loading-spinner" style={{ width: '40px', height: '40px' }}></div>
  </div>
);

/**
 * Main Index page component
 * Handles layout structure and CDN initialization
 */
const Index: React.FC = () => {
  // Track initial render to avoid unnecessary reloads
  const hasInitialized = useRef<boolean>(false);
  
  // Use our animation observer hook
  useMultiElementAnimationObserver('.animate-reveal');
  
  // Add CSS to prevent layout shifts
  useEffect(() => {
    // Add CSS rule for setting fallback heights
    const style = document.createElement('style');
    style.innerHTML = `
      :root {
        --doc-height: 100vh;
      }
      section {
        min-height: var(--doc-height);
        contain: content;
      }
      .loading-spinner {
        border: 3px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        border-top: 3px solid #990000;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      
      /* Force footer to be visible */
      footer {
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
      }
      
      .footer-animate {
        opacity: 1 !important;
        transform: translateY(0) !important;
        transition: opacity 0.3s ease, transform 0.3s ease;
      }
      
      .footer-animate.visible {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
      
      /* Debug utility */
      .debug-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        color: white;
        padding: 10px;
        z-index: 9999;
        font-size: 12px;
        max-width: 50%;
        max-height: 50%;
        overflow: auto;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  // Initialize CDN and scroll to top on first render
  useEffect(() => {
    // Log that component is mounting
    console.log('Index component mounting');
    
    if (!hasInitialized.current) {
      console.log('First render of Index, initializing...');
      
      // Ensure CDN is initialized
      initializeCDNStrategy();
      
      // Scroll to top
      window.scrollTo(0, 0);
      hasInitialized.current = true;
      
      // Ensure footer is visible after a slight delay
      setTimeout(() => {
        const footer = document.querySelector('footer');
        if (footer) {
          footer.style.display = 'block';
          footer.style.opacity = '1';
          footer.style.visibility = 'visible';
          console.log('Footer visibility enforced');
        }
      }, 2000);
    }
    
    // Add debug helper in development mode
    if (process.env.NODE_ENV === 'development' || window.location.search.includes('debug=true')) {
      const debugDiv = document.createElement('div');
      debugDiv.className = 'debug-overlay';
      debugDiv.textContent = `Page loaded at: ${new Date().toLocaleTimeString()}`;
      document.body.appendChild(debugDiv);
      
      const updateDebug = setInterval(() => {
        if (debugDiv.parentNode) {
          debugDiv.textContent = `
            Last update: ${new Date().toLocaleTimeString()}
            Components loaded: ${document.querySelectorAll('section').length}
            Scripts loaded: ${document.querySelectorAll('script').length}
            Styles loaded: ${document.querySelectorAll('link[rel="stylesheet"]').length}
            Images loaded: ${document.querySelectorAll('img').length}
          `;
        } else {
          clearInterval(updateDebug);
        }
      }, 1000);
      
      return () => {
        clearInterval(updateDebug);
        if (debugDiv.parentNode) {
          debugDiv.parentNode.removeChild(debugDiv);
        }
      };
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden">
      {/* SEO Components */}
      <MetaTags />
      <StructuredData pageType="homepage" />
      <ScrollHandler />
      
      {/* Layout Components with Suspense boundaries and fixed height placeholders */}
      <Suspense fallback={<div className="h-16 bg-white shadow-sm" aria-hidden="true"></div>}>
        <Navbar />
      </Suspense>
      
      <main>
        <Suspense fallback={<SectionLoading />}>
          <Hero />
        </Suspense>
        
        <Suspense fallback={<SectionLoading />}>
          <div style={{ contain: 'size layout', aspectRatio: '16/9', minHeight: '500px' }}>
            <Services />
          </div>
        </Suspense>
        
        <Suspense fallback={<SectionLoading />}>
          <div style={{ contain: 'size layout', aspectRatio: '16/9', minHeight: '500px' }}>
            <Features />
          </div>
        </Suspense>
        
        <Suspense fallback={<SectionLoading />}>
          <Contact />
        </Suspense>
      </main>
      
      <Suspense fallback={<FooterLoading />}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Index;
