
import { useEffect, useState } from "react";

export const navLinks = [
  {
    name: "Start",
    href: "/",
  },
  {
    name: "Lösungen",
    href: "/#services",
  },
  {
    name: "Vorteile",
    href: "/#features",
  },
  {
    name: "Kontakt",
    href: "/#contact",
  },
];

export type NavLink = {
  name: string;
  href: string;
};
