
/**
 * Strategy Manager Module
 * 
 * Manages CDN selection strategies
 */

import { setCDNSelectionStrategy, CDNSelectionStrategy } from '../cdnConfig';

/**
 * Initializes the CDN strategy based on URL parameters or localStorage
 * @returns The selected CDN strategy
 */
export const initializeStrategy = (): CDNSelectionStrategy => {
  // URL-Parameter überprüfen (für Testzwecke)
  const urlParams = new URLSearchParams(window.location.search);
  const cdnStrategy = urlParams.get('cdn-strategy');
  
  if (cdnStrategy === 'random') {
    // Zufällige CDN-Strategie aktivieren
    setCDNSelectionStrategy(CDNSelectionStrategy.RANDOM);
    console.log('CDN-Strategie: Zufällig');
    return CDNSelectionStrategy.RANDOM;
  } else if (cdnStrategy === 'deterministic') {
    // Deterministische CDN-Strategie aktivieren
    setCDNSelectionStrategy(CDNSelectionStrategy.DETERMINISTIC);
    console.log('CDN-Strategie: Deterministisch');
    return CDNSelectionStrategy.DETERMINISTIC;
  } else {
    // Standard-Strategie aus localStorage oder Config verwenden
    const savedStrategy = localStorage.getItem('cdn-strategy');
    
    if (savedStrategy === 'random') {
      setCDNSelectionStrategy(CDNSelectionStrategy.RANDOM);
      console.log('CDN-Strategie aus localStorage: Zufällig');
      return CDNSelectionStrategy.RANDOM;
    } else {
      // Standardmäßig deterministisch für besseres Caching
      setCDNSelectionStrategy(CDNSelectionStrategy.DETERMINISTIC);
      console.log('CDN-Strategie: Standard (Deterministisch)');
      return CDNSelectionStrategy.DETERMINISTIC;
    }
  }
};

/**
 * CDN-Strategie speichern und aktivieren
 * @param strategy Die zu speichernde Strategie
 */
export const saveCDNStrategy = (strategy: CDNSelectionStrategy): void => {
  localStorage.setItem('cdn-strategy', strategy);
  setCDNSelectionStrategy(strategy);
};
