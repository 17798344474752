
import React from "react";

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  size?: "default" | "narrow" | "wide";
}

export function Container({ 
  children, 
  className = "",
  size = "default" 
}: ContainerProps) {
  const sizeClasses = {
    default: "max-w-7xl",
    narrow: "max-w-3xl",
    wide: "max-w-screen-2xl"
  };

  const sizeClass = sizeClasses[size];

  return (
    <div className={`mx-auto w-full px-4 sm:px-6 md:px-8 lg:px-12 ${sizeClass} ${className}`}>
      {children}
    </div>
  );
}
