
/**
 * Domain Conflict Handler
 * 
 * Handles conflicts between main domain and CDN to prevent duplicate asset loading
 */

import { convertMainDomainToCdn } from '../cdnConfig';
import { markAssetAsLoaded } from './assetTracker';

/**
 * Prevents scripts from www.nex2go.de from loading when already on CDN
 */
export function preventMainDomainLoading(): void {
  // Create a MutationObserver to watch for new script/link elements
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          // Check if it's an Element
          if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element;
            
            // Check for scripts from main domain
            if (element.tagName === 'SCRIPT') {
              const script = element as HTMLScriptElement;
              if (script.src && script.src.includes('www.nex2go.de')) {
                // Convert to CDN URL
                const cdnUrl = convertMainDomainToCdn(script.src);
                console.log(`Preventing main domain script load: ${script.src} -> ${cdnUrl}`);
                
                // Try to create a new script with CDN URL instead
                if (cdnUrl !== script.src) {
                  const newScript = document.createElement('script');
                  newScript.src = cdnUrl;
                  newScript.type = script.type;
                  newScript.async = script.async;
                  newScript.defer = script.defer;
                  newScript.crossOrigin = 'anonymous';
                  newScript.dataset.cdnLoaded = 'true';
                  
                  // Copy other attributes
                  Array.from(script.attributes).forEach(attr => {
                    if (attr.name !== 'src') {
                      newScript.setAttribute(attr.name, attr.value);
                    }
                  });
                  
                  // Mark as loaded to prevent doubles
                  markAssetAsLoaded(cdnUrl, 'preventMainDomainLoading');
                  
                  // Replace the script
                  if (script.parentNode) {
                    script.parentNode.replaceChild(newScript, script);
                  }
                }
              }
            }
            
            // Check for links (CSS, etc) from main domain
            if (element.tagName === 'LINK') {
              const link = element as HTMLLinkElement;
              if (link.href && link.href.includes('www.nex2go.de')) {
                // Convert to CDN URL
                const cdnUrl = convertMainDomainToCdn(link.href);
                if (cdnUrl !== link.href) {
                  console.log(`Preventing main domain link load: ${link.href} -> ${cdnUrl}`);
                  link.href = cdnUrl;
                  link.crossOrigin = 'anonymous';
                  link.dataset.cdnLoaded = 'true';
                  markAssetAsLoaded(cdnUrl, 'preventMainDomainLoading');
                }
              }
            }
            
            // Check for images from main domain
            if (element.tagName === 'IMG') {
              const img = element as HTMLImageElement;
              if (img.src && img.src.includes('www.nex2go.de')) {
                // Convert to CDN URL
                const cdnUrl = convertMainDomainToCdn(img.src);
                if (cdnUrl !== img.src) {
                  console.log(`Converting main domain image: ${img.src} -> ${cdnUrl}`);
                  img.src = cdnUrl;
                  img.crossOrigin = 'anonymous';
                  img.dataset.cdnLoaded = 'true';
                  markAssetAsLoaded(cdnUrl, 'preventMainDomainLoading');
                }
              }
            }
          }
        });
      }
    });
  });
  
  // Start observing the document
  observer.observe(document, { childList: true, subtree: true });
}
