
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import { Container } from "@/components/ui/Container";
import { CheckCircle, Award, Users, Shield, HeadphonesIcon, Server, UserCheck, Monitor, Globe, Wrench } from "lucide-react";
import { useEffect } from "react";

export default function AboutUs() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <Container>
          <div className="bg-card dark:bg-nex-900 shadow-md rounded-lg p-8 border border-border animate-fade-in">
            <h1 className="text-3xl md:text-4xl font-bold mb-8 pb-4 border-b border-border dark:border-nex-800 text-red-800 dark:text-red-600">Über uns</h1>
            
            <div className="prose prose-lg max-w-none dark:prose-invert">
              <p className="text-lg mb-8 animate-slide-up [animation-delay:100ms]">
                Willkommen beim NEX2GO Solution Center – Ihrem zuverlässigen Partner für Webhosting, Domainregistrierung und individuelle IT-Lösungen. Seit mindestens 2005 stehen wir unseren Kunden mit maßgeschneiderten Hosting-Services und persönlichem Support zur Seite.
              </p>
              
              <section className="mb-12 animate-slide-up [animation-delay:200ms]">
                <h2 className="text-2xl font-semibold mb-6 text-red-800 dark:text-red-600 flex items-center">
                  <Award className="mr-3 h-6 w-6" />
                  Unsere Mission
                </h2>
                <p>
                  Unsere Mission ist es, Unternehmen und Privatpersonen mit leistungsstarken, sicheren und flexiblen Webhosting-Lösungen zu unterstützen. Dabei legen wir besonderen Wert auf Kundennähe, erstklassigen Service und innovative Technologien.
                </p>
              </section>
              
              <section className="mb-12 bg-muted dark:bg-nex-800 p-6 rounded-lg animate-slide-up [animation-delay:300ms]">
                <h2 className="text-2xl font-semibold mb-6 text-red-800 dark:text-red-600 flex items-center">
                  <Shield className="mr-3 h-6 w-6" />
                  Warum NEX2GO?
                </h2>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <CheckCircle className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <span><strong>Erfahrung & Kompetenz</strong> – Mit über 15 Jahren Erfahrung bieten wir professionelle IT-Dienstleistungen auf höchstem Niveau.</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <span><strong>Individuelle Lösungen</strong> – Wir gehen auf Ihre speziellen Anforderungen ein und bieten passgenaue Hosting- und Domain-Lösungen.</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <span><strong>Persönlicher Support</strong> – Unser Team steht Ihnen mit freundlicher und schneller Unterstützung jederzeit zur Verfügung.</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <span><strong>Sicherheit & Performance</strong> – Moderne Servertechnologie und hohe Sicherheitsstandards garantieren eine zuverlässige Verfügbarkeit Ihrer Online-Projekte.</span>
                  </li>
                </ul>
              </section>
              
              <section className="mb-12 animate-slide-up [animation-delay:400ms]">
                <h2 className="text-2xl font-semibold mb-6 text-red-800 dark:text-red-600 flex items-center">
                  <Server className="mr-3 h-6 w-6" />
                  Unsere Leistungen
                </h2>
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <Globe className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Webhosting</h3>
                      <p className="text-sm text-muted-foreground">Skalierbare Hosting-Pakete für jede Anforderung</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <Globe className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Domainregistrierung</h3>
                      <p className="text-sm text-muted-foreground">Ihre Wunschdomain einfach und schnell sichern</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <Server className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Serverlösungen</h3>
                      <p className="text-sm text-muted-foreground">Leistungsstarke Server für Unternehmen und Entwickler</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <HeadphonesIcon className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">IT-Support & Beratung</h3>
                      <p className="text-sm text-muted-foreground">Professionelle Beratung für individuelle Anforderungen</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <Users className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Firmenkunden-Betreuung</h3>
                      <p className="text-sm text-muted-foreground">Maßgeschneiderte IT-Lösungen für Unternehmen</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg transition-all hover:shadow-md">
                    <Monitor className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Managed Services</h3>
                      <p className="text-sm text-muted-foreground">Umfassende Betreuung und Wartung Ihrer IT-Infrastruktur</p>
                    </div>
                  </div>
                  <div className="flex items-start p-4 bg-card dark:bg-nex-800/50 border border-border dark:border-nex-800 rounded-lg md:col-span-2 transition-all hover:shadow-md">
                    <Wrench className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">PC- & Laptop-Reparatur</h3>
                      <p className="text-sm text-muted-foreground">Professionelle Fehlerdiagnose, Wartung und Reparatur von Computern und Laptops</p>
                    </div>
                  </div>
                </div>
              </section>
              
              <section className="mb-12 bg-muted dark:bg-nex-800 p-6 rounded-lg animate-slide-up [animation-delay:500ms]">
                <h2 className="text-2xl font-semibold mb-6 text-red-800 dark:text-red-600 flex items-center">
                  <Users className="mr-3 h-6 w-6" />
                  Spezialisierung auf Firmenkunden
                </h2>
                <p className="mb-4">
                  Wir wissen, dass Unternehmen individuelle IT-Lösungen benötigen, die auf ihre speziellen Anforderungen zugeschnitten sind. Deshalb bieten wir:
                </p>
                <ul className="space-y-4 mt-4">
                  <li className="flex items-start">
                    <UserCheck className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Individuelle Beratung</h3>
                      <p>Wir analysieren Ihre Anforderungen und entwickeln die optimale Lösung für Sie.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <Server className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Skalierbare Hosting-Pakete</h3>
                      <p>Von kleinen Unternehmen bis hin zu großen Firmen bieten wir flexible und leistungsstarke Hosting-Lösungen.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <Shield className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Sicherheit & Datenschutz</h3>
                      <p>Unsere Server entsprechen höchsten Sicherheitsstandards und garantieren Datenschutz nach DSGVO.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <Users className="h-6 w-6 text-red-800 dark:text-red-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium">Persönlicher Ansprechpartner</h3>
                      <p>Ein dedizierter Experte steht Ihnen für alle technischen Fragen zur Seite.</p>
                    </div>
                  </li>
                </ul>
              </section>
              
              <section className="text-center py-8 rounded-lg bg-gradient-to-br from-card to-muted dark:from-nex-900 dark:to-nex-800 p-8 animate-scale-in [animation-delay:600ms]">
                <h2 className="text-2xl font-semibold mb-4 text-red-800 dark:text-red-600">Ihr Erfolg ist unser Antrieb!</h2>
                <p className="mb-4">
                  Ob Start-up, KMU oder Privatperson – wir bieten Ihnen die ideale Plattform für Ihren digitalen Erfolg. Lassen Sie uns gemeinsam Ihre Online-Präsenz auf das nächste Level heben.
                </p>
                <p className="font-semibold text-lg mb-6">
                  Kontaktieren Sie uns noch heute und starten Sie mit NEX2GO in die Zukunft!
                </p>
                <div>
                  <a 
                    href="/#contact" 
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-800 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                  >
                    Kontakt aufnehmen
                  </a>
                </div>
              </section>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
