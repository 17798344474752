
/**
 * Asset Manager
 * 
 * Zentrale Verwaltung für alle Assets mit CDN-Unterstützung
 */

import { CDN_URLS, selectCdnUrl, PRIMARY_CDN } from './cdnConfig';

// Map für gecachte Assets
const assetCache = new Map<string, string>();

// Basis-URL für lokale Assets
const BASE_ASSET_PATH = '/assets';

/**
 * Enum für Asset-Typen zur besseren Kategorisierung
 */
export enum AssetType {
  IMAGE = 'images',
  FONT = 'fonts',
  SCRIPT = 'js',
  STYLE = 'css',
  OTHER = 'other'
}

/**
 * Generiert einen CDN-Pfad für das angegebene Asset
 * 
 * @param assetPath Relativer Pfad zum Asset
 * @param type Asset-Typ für optimiertes Routing (optional)
 * @returns Vollständiger CDN-Pfad
 */
export const getLocalCDNUrl = (assetPath: string, type: AssetType = AssetType.OTHER): string => {
  // Skip if already a full URL with http/https
  if (assetPath.startsWith('http')) {
    return assetPath;
  }
  
  // Wenn der Pfad bereits im Cache ist, gib ihn zurück
  if (assetCache.has(assetPath)) {
    return assetCache.get(assetPath)!;
  }

  // Stelle sicher, dass der Pfad mit einem Slash beginnt
  const normalizedPath = assetPath.startsWith('/') 
    ? assetPath 
    : `/${assetPath}`;

  let finalPath = normalizedPath;
  
  // Spezielle Behandlung für Bilder
  if (type === AssetType.IMAGE) {
    // Stelle sicher, dass wir nur WebP-Dateien verwenden
    if (!finalPath.endsWith('.webp') && 
        (finalPath.endsWith('.jpg') || 
         finalPath.endsWith('.jpeg') || 
         finalPath.endsWith('.png'))) {
      const basePath = finalPath.substring(0, finalPath.lastIndexOf('.'));
      finalPath = `${basePath}.webp`;
    }
    
    // Wenn der Pfad nicht den "images" Ordner enthält, füge ihn hinzu
    if (!finalPath.includes('/images/')) {
      finalPath = `/images${finalPath}`;
    }
  }
  
  // Spezielle Behandlung für Schriftarten
  else if (type === AssetType.FONT) {
    // Wenn der Pfad nicht den "fonts" Ordner enthält, füge ihn hinzu
    if (!finalPath.includes('/fonts/')) {
      finalPath = `/fonts${finalPath}`;
    }
  }
  
  // Spezielle Behandlung für Skripte
  else if (type === AssetType.SCRIPT) {
    // Wenn der Pfad nicht den "js" Ordner enthält, füge ihn hinzu
    if (!finalPath.includes('/js/')) {
      finalPath = `/js${finalPath}`;
    }
  }
  
  // Spezielle Behandlung für Stylesheets
  else if (type === AssetType.STYLE) {
    // Wenn der Pfad nicht den "css" Ordner enthält, füge ihn hinzu
    if (!finalPath.includes('/css/')) {
      finalPath = `/css${finalPath}`;
    }
  }

  // Wähle eine CDN-URL für das Asset
  const cdnBaseUrl = selectCdnUrl(assetPath);
  
  // Erstelle den vollständigen CDN-Pfad
  const cdnPath = `${cdnBaseUrl}${BASE_ASSET_PATH}${finalPath}`;
  
  // Speichere im Cache für zukünftige Anfragen
  assetCache.set(assetPath, cdnPath);
  
  return cdnPath;
};

/**
 * Konvertiert jeden relativen URL in einen CDN-URL
 * Hilfsmethode, um sicherzustellen, dass alle Assets über CDN geladen werden
 */
export const ensureCDNUrl = (url: string): string => {
  // If already a full URL or CDN URL, return as is
  if (url.startsWith('http') || url.includes('cdn.nex2go-it.de')) {
    return url;
  }
  
  // If it's a relative URL, convert to CDN URL
  const normalizedPath = url.startsWith('/') ? url : `/${url}`;
  
  // If it points to an asset, use the asset manager
  if (normalizedPath.includes('/assets/')) {
    const assetPath = normalizedPath.replace('/assets/', '');
    const assetType = getAssetTypeFromPath(assetPath);
    return getLocalCDNUrl(assetPath, assetType);
  }
  
  // For other URLs, just prepend the CDN domain
  return `${PRIMARY_CDN}${normalizedPath}`;
};

/**
 * Determines the asset type from file path
 */
const getAssetTypeFromPath = (path: string): AssetType => {
  if (path.match(/\.(jpg|jpeg|png|gif|webp|svg|avif)$/i)) {
    return AssetType.IMAGE;
  }
  if (path.match(/\.(woff|woff2|ttf|otf|eot)$/i)) {
    return AssetType.FONT;
  }
  if (path.match(/\.(js|mjs)$/i)) {
    return AssetType.SCRIPT;
  }
  if (path.match(/\.(css)$/i)) {
    return AssetType.STYLE;
  }
  return AssetType.OTHER;
};
