
import React, { useEffect, lazy, Suspense, useRef } from "react";
import { Container } from "./ui/Container";
import { FooterInfo } from "./footer/FooterInfo";
import { FooterCopyright } from "./footer/FooterCopyright";
import { PRIMARY_CDN } from "@/utils/asset/cdnConfig";

// Use lazy loading for non-critical components
const QuickLinks = lazy(() => import("./footer/FooterLinks").then(module => ({
  default: module.QuickLinks
})));

const ContactLinks = lazy(() => import("./footer/FooterLinks").then(module => ({
  default: module.ContactLinks
})));

export function Footer() {
  // Determine if this is the homepage
  const isHomePage = window.location.pathname === "/" || window.location.pathname === "/index.html";
  const footerScriptLoaded = useRef(false);
  
  // Initialize footer functionality with optimized script loading
  useEffect(() => {
    // Only load the script when the component is visible and not already loaded
    if (footerScriptLoaded.current) return;
    
    // Define PRIMARY_CDN if not already defined
    if (typeof window.PRIMARY_CDN === 'undefined') {
      window.PRIMARY_CDN = PRIMARY_CDN;
    }
    
    // Make the footer visible immediately
    const footerElement = document.querySelector('footer');
    if (footerElement) {
      footerElement.classList.add('!opacity-100');
      footerElement.style.display = 'block';
      footerElement.style.visibility = 'visible';
      
      // Add visible class to all footer-animate elements
      const animatedElements = footerElement.querySelectorAll('.footer-animate');
      animatedElements.forEach(el => {
        if (el instanceof HTMLElement) {
          el.classList.add('visible');
          el.style.opacity = '1';
          el.style.transform = 'translateY(0)';
        }
      });
    }
    
    // Load Footer.js script
    const scriptSrc = `${PRIMARY_CDN}/assets/js/Footer.js`;
    
    // Check if script is already loaded
    if (!document.querySelector(`script[src="${scriptSrc}"]`) && 
        !document.querySelector(`script[data-script-id="footer-js"]`)) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.crossOrigin = 'anonymous';
      script.dataset.scriptId = 'footer-js';
      script.type = 'text/javascript';
      document.body.appendChild(script);
      
      script.onload = () => {
        console.log('Footer script loaded successfully');
        footerScriptLoaded.current = true;
      };
      
      script.onerror = (error) => {
        console.error('Error loading footer script:', error);
        
        // Fallback: Try to load index2.js directly
        const index2Src = `${PRIMARY_CDN}/assets/js/index2.js`;
        
        try {
          const moduleScript = document.createElement('script');
          moduleScript.type = 'module';
          moduleScript.textContent = `
            import { makeFooterVisible } from '${index2Src}';
            makeFooterVisible();
          `;
          document.body.appendChild(moduleScript);
        } catch (moduleError) {
          console.error('Fallback module loading failed:', moduleError);
        }
        
        // Make the footer visible anyway
        const footer = document.querySelector('footer');
        if (footer) {
          footer.style.display = 'block';
          footer.style.opacity = '1';
          footer.style.visibility = 'visible';
        }
      };
    } else {
      footerScriptLoaded.current = true;
    }

    // Safety timeout to ensure footer is visible
    setTimeout(() => {
      const footer = document.querySelector('footer');
      if (footer) {
        footer.style.display = 'block';
        footer.style.opacity = '1';
        footer.style.visibility = 'visible';
      }
    }, 1000);

    return () => {
      // No need to remove the script on unmount as it might be needed by other components
    };
  }, []);

  // Loading fallback for lazy-loaded components
  const LinksFallback = () => (
    <div className="animate-pulse">
      <div className="h-4 bg-white/20 rounded w-3/4 mb-3"></div>
      <div className="h-3 bg-white/20 rounded w-1/2 mb-2"></div>
      <div className="h-3 bg-white/20 rounded w-2/3 mb-2"></div>
      <div className="h-3 bg-white/20 rounded w-1/3"></div>
    </div>
  );

  return (
    <footer className="bg-red-800 dark:bg-red-900 py-8 sm:py-10 md:py-12 text-white !opacity-100 block text-center" style={{display: 'block', opacity: '1', visibility: 'visible'}}>
      <Container className="mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8 footer-animate visible mx-auto" style={{opacity: '1', transform: 'translateY(0)'}}>
          <FooterInfo />
          <Suspense fallback={<LinksFallback />}>
            <QuickLinks isHomePage={isHomePage} />
          </Suspense>
          <Suspense fallback={<LinksFallback />}>
            <ContactLinks />
          </Suspense>
          <div className="hidden sm:block md:hidden"></div>
        </div>

        <FooterCopyright />
      </Container>
    </footer>
  );
}
