
/**
 * DOM Processor Module
 * 
 * Handles DOM element processing for CDN initialization
 */

import { PRIMARY_CDN } from '../cdnConfig';
import { ensureCDNUrl } from '../assetManager';
import { removeDuplicateScripts } from './scriptHandler';

// Element selectors for CDN processing
const SELECTORS = {
  IMAGES: 'img[src]:not([data-cdn-loaded])',
  SCRIPTS: 'script[src]:not([data-cdn-loaded])',
  LINKS: 'link[href]:not([data-cdn-loaded])',
  IFRAMES: 'iframe[src]:not([data-cdn-loaded])',
  MODULE_PRELOADS: 'link[rel="modulepreload"][href^="/"]'
};

/**
 * Processes all src attributes in the document to ensure CDN URLs are used
 */
export const processSrcAttributes = (): void => {
  // Process all element types that can have external resources
  processImageSources();
  processScriptSources();
  processLinkSources();
  processIframeSources();
};

/**
 * Processes all image src attributes
 */
function processImageSources(): void {
  document.querySelectorAll(SELECTORS.IMAGES).forEach((img) => {
    processSrcAttribute(img, 'src');
  });
}

/**
 * Processes all script src attributes
 */
function processScriptSources(): void {
  document.querySelectorAll(SELECTORS.SCRIPTS).forEach((script) => {
    if (processSrcAttribute(script, 'src')) {
      script.setAttribute('crossorigin', 'anonymous');
    }
  });
}

/**
 * Processes all link href attributes
 */
function processLinkSources(): void {
  document.querySelectorAll(SELECTORS.LINKS).forEach((link) => {
    if (processSrcAttribute(link, 'href')) {
      link.setAttribute('crossorigin', 'anonymous');
    }
  });
}

/**
 * Processes all iframe src attributes
 */
function processIframeSources(): void {
  document.querySelectorAll(SELECTORS.IFRAMES).forEach((iframe) => {
    processSrcAttribute(iframe, 'src');
  });
}

/**
 * Processes a single src/href attribute on an element
 * @param element DOM element to process
 * @param attrName Attribute name ('src' or 'href')
 * @returns Boolean indicating if the attribute was updated
 */
function processSrcAttribute(element: Element, attrName: 'src' | 'href'): boolean {
  const origValue = element.getAttribute(attrName);
  
  if (origValue && !origValue.includes('data:') && !origValue.includes('blob:')) {
    const cdnValue = ensureCDNUrl(origValue);
    
    if (cdnValue !== origValue) {
      element.setAttribute(attrName, cdnValue);
      element.setAttribute('data-cdn-loaded', 'true');
      return true;
    }
  }
  
  return false;
}

/**
 * Fixes modulepreload links to use CDN URLs
 */
export const fixModulePreloadResources = (): void => {
  // Get all modulepreload links without the CDN domain
  const modulePreloads = document.querySelectorAll(SELECTORS.MODULE_PRELOADS);
  
  // Replace each one with the CDN version
  modulePreloads.forEach(link => {
    if (!link.getAttribute('data-cdn-loaded')) {
      const href = link.getAttribute('href');
      if (href) {
        // Create a new link with the CDN URL
        const cdnUrl = `${PRIMARY_CDN}${href}`;
        const newLink = document.createElement('link');
        newLink.rel = 'modulepreload';
        newLink.href = cdnUrl;
        newLink.crossOrigin = 'anonymous';
        newLink.setAttribute('data-cdn-loaded', 'true');
        
        // Replace the old link
        if (link.parentNode) {
          link.parentNode.replaceChild(newLink, link);
        }
      }
    }
  });
  
  // Check for duplicate script tags
  removeDuplicateScripts();
}
