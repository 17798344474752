
import { Container } from "@/components/ui/Container";
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import { Shield, Clock, User, Database, FileText, Server, Key } from "lucide-react";
import { useEffect } from "react";

export default function Datenschutz() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <Container>
          <div className="bg-card dark:bg-nex-900 shadow-md rounded-lg p-8 border border-border animate-fade-in">
            <h1 className="text-2xl md:text-3xl font-bold mb-8 pb-4 border-b border-border dark:border-nex-800 flex items-center text-red-800 dark:text-red-600">
              <Shield className="mr-3 h-6 w-6" />
              Datenschutzerklärung
            </h1>
            
            <section className="mb-8 animate-slide-up [animation-delay:100ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                1. Allgemeine Hinweise
              </h2>
              <p className="mb-4">Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. In dieser Datenschutzerklärung informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:200ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <User className="mr-2 h-5 w-5" />
                2. Verantwortlicher
              </h2>
              <p>NEX2GO solution center</p>
              <p>Waldemar Wetzel</p>
              <p>Hochfeldring 92/2</p>
              <p>76549 Hügelsheim</p>
              <p>info@nex2go.de</p>
              <p>+49 1520 58 666 90</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:300ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <Database className="mr-2 h-5 w-5" />
                3. Erhebung und Verarbeitung personenbezogener Daten
              </h2>
              <p className="mb-4">Wir verarbeiten Ihre personenbezogenen Daten nur im Rahmen der gesetzlichen Bestimmungen. Folgende Daten können erhoben werden:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Server-Logfiles (IP-Adresse, Browsertyp, Zugriffszeit, besuchte Seiten)</li>
                <li>Kontaktformular-Daten (Name, E-Mail-Adresse, Nachricht)</li>
                <li>Newsletter-Anmeldung (E-Mail-Adresse, ggf. Name)</li>
                <li>Cookies und Tracking-Technologien (siehe Abschnitt 6)</li>
                <li>Daten von Drittanbietern wie Zahlungsdienstleister oder Analyse-Tools</li>
              </ul>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:400ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                4. Zwecke der Datenverarbeitung
              </h2>
              <p className="mb-4">Die Verarbeitung Ihrer Daten erfolgt zu folgenden Zwecken:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Bereitstellung und Verbesserung unserer Webseite</li>
                <li>Bearbeitung von Anfragen</li>
                <li>Versenden von Newslettern</li>
                <li>Analyse des Nutzerverhaltens zur Optimierung unseres Angebots</li>
                <li>Erfüllung gesetzlicher Verpflichtungen</li>
                <li>Zahlungsabwicklung (bei Nutzung von Zahlungsdienstleistern)</li>
              </ul>
            </section>

            <div className="mb-8 bg-muted dark:bg-nex-800 p-6 rounded-lg animate-slide-up [animation-delay:500ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <FileText className="mr-2 h-5 w-5" />
                5. Rechtsgrundlagen der Verarbeitung
              </h2>
              <p className="mb-4">Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 DSGVO auf Basis folgender Rechtsgrundlagen:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)</li>
                <li>Vertragserfüllung oder vorvertragliche Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO)</li>
                <li>Rechtliche Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO)</li>
                <li>Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO)</li>
              </ul>
            </div>

            <section className="mb-8 animate-slide-up [animation-delay:600ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                6. Cookies und Tracking-Technologien
              </h2>
              <p className="mb-4">Wir verwenden Cookies und vergleichbare Technologien, um unsere Webseite zu verbessern. Hierbei setzen wir unter anderem Google Analytics und Facebook Pixel ein. Diese Dienste erfassen Informationen über das Nutzerverhalten, um unsere Inhalte und Werbung zu optimieren. Sie können der Nutzung jederzeit widersprechen oder Ihre Einstellungen anpassen.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:700ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <Server className="mr-2 h-5 w-5" />
                7. Hosting & Drittanbieter-Services
              </h2>
              <p className="mb-4">Unsere Server werden von uns selbst betrieben. Wir speichern und verarbeiten die oben genannten Daten ausschließlich auf unseren eigenen Servern. Zudem setzen wir Drittanbieter wie Zahlungsdienstleister oder Newsletter-Services ein, die personenbezogene Daten verarbeiten. Eine Liste dieser Anbieter finden Sie in Abschnitt 9.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:800ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                8. Auftragsverarbeitung
              </h2>
              <p className="mb-4">Falls personenbezogene Daten an externe Dienstleister weitergegeben werden, geschieht dies auf Basis von Auftragsverarbeitungsverträgen gemäß Art. 28 DSGVO. Diese Verträge stellen sicher, dass Ihre Daten nur für den vorgesehenen Zweck verwendet werden.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:900ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <Clock className="mr-2 h-5 w-5" />
                9. Speicherdauer
              </h2>
              <p className="mb-4">Wir speichern Ihre Daten nur so lange, wie es für die jeweiligen Zwecke erforderlich ist oder gesetzliche Aufbewahrungspflichten bestehen. Konkrete Speicherfristen:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Server-Logfiles: 6 Monate</li>
                <li>Kontaktanfragen: 1 Jahr nach Abschluss der Bearbeitung</li>
                <li>Newsletter-Daten: Bis zum Widerruf der Einwilligung</li>
                <li>Zahlungsinformationen: 10 Jahre aufgrund steuerlicher Vorgaben</li>
              </ul>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:1000ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600 flex items-center">
                <Key className="mr-2 h-5 w-5" />
                10. Ihre Rechte
              </h2>
              <p className="mb-4">Sie haben das Recht auf:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Auskunft (Art. 15 DSGVO)</li>
                <li>Berichtigung (Art. 16 DSGVO)</li>
                <li>Löschung (Art. 17 DSGVO)</li>
                <li>Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                <li>Datenübertragbarkeit (Art. 20 DSGVO)</li>
                <li>Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</li>
              </ul>
              <p className="mt-4">Zur Geltendmachung Ihrer Rechte können Sie uns unter den angegebenen Kontaktdaten erreichen.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:1100ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                11. Beschwerderecht bei der Aufsichtsbehörde
              </h2>
              <p className="mb-4">Sie haben das Recht, sich bei einer Datenschutzaufsichtsbehörde zu beschweren, wenn Sie der Meinung sind, dass Ihre Daten unrechtmäßig verarbeitet wurden.</p>
            </section>

            <section className="animate-slide-up [animation-delay:1200ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                12. Änderungen der Datenschutzerklärung
              </h2>
              <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um sie an geänderte rechtliche Vorgaben oder technische Entwicklungen anzupassen.</p>
            </section>

            <div className="mt-10 text-sm text-muted-foreground">
              <p>Stand: Juni 2024</p>
              <p>NEX2GO solution center | Waldemar Wetzel | Hochfeldring 92/2 | 76549 Hügelsheim</p>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
