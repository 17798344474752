
import React from 'react';
import { Building2, MapPin, Globe } from 'lucide-react';

export function FooterInfo() {
  return (
    <div className="footer-info text-center md:text-left">
      <h3 className="text-xl font-semibold mb-4">NEX2GO solution center</h3>
      <p className="mb-2 flex items-center gap-2 justify-center md:justify-start">
        <Building2 className="h-4 w-4" />
        <span>Ihr Partner für digitale Transformation</span>
      </p>
      <p className="mb-2 flex items-center gap-2 justify-center md:justify-start">
        <MapPin className="h-4 w-4" />
        <span>Hügelsheim, Germany</span>
      </p>
      <div className="mt-4 flex space-x-3 justify-center md:justify-start">
        <a href="https://linkedin.com" className="text-white hover:text-gray-300" aria-label="LinkedIn">
          <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
        </a>
        <a href="https://twitter.com" className="text-white hover:text-gray-300" aria-label="Twitter">
          <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
    </div>
  );
}
