
import React, { useState, useEffect } from 'react';
import { CDNSelectionStrategy, getCDNSelectionStrategy } from '../../utils/asset/cdnConfig';
import { saveCDNStrategy } from '../../utils/asset/cdnInitializer';
import { Button } from "../ui/button";
import { toast } from 'sonner';

interface CDNStrategyControlProps {
  showAlways?: boolean; // Für Testzwecke oder Admin-Dashboard
}

/**
 * Komponente zur Kontrolle der CDN-Strategie (nur für Entwickler oder Admin sichtbar)
 */
const CDNStrategyControl: React.FC<CDNStrategyControlProps> = ({ showAlways = false }) => {
  const [strategy, setStrategy] = useState<CDNSelectionStrategy>(getCDNSelectionStrategy());
  const [isVisible, setIsVisible] = useState<boolean>(showAlways || false);
  const [loadedScripts, setLoadedScripts] = useState<number>(0);
  
  // Überprüfe, ob es sich um eine Entwicklungsumgebung handelt
  useEffect(() => {
    // Im Entwicklungsmodus oder bei Admin-Login anzeigen
    const isDev = process.env.NODE_ENV === 'development';
    const isAdmin = localStorage.getItem('adminAccess') === 'true';
    
    setIsVisible(showAlways || isDev || isAdmin);
    
    // Zähle geladene Skripte zur Diagnose
    if (showAlways || isDev || isAdmin) {
      const scripts = document.querySelectorAll('script[src]');
      setLoadedScripts(scripts.length);
    }
  }, [showAlways]);
  
  // Aktualisiere die Skriptzahl alle 2 Sekunden, wenn sichtbar
  useEffect(() => {
    if (!isVisible) return;
    
    const interval = setInterval(() => {
      const scripts = document.querySelectorAll('script[src]');
      setLoadedScripts(scripts.length);
    }, 2000);
    
    return () => clearInterval(interval);
  }, [isVisible]);
  
  // Wenn nicht sichtbar sein soll, nichts rendern
  if (!isVisible) return null;
  
  const handleStrategyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStrategy = event.target.value as CDNSelectionStrategy;
    setStrategy(newStrategy);
    saveCDNStrategy(newStrategy);
    
    toast.success(`CDN-Strategie auf "${newStrategy === CDNSelectionStrategy.RANDOM ? 'Zufällig' : 'Deterministisch'}" geändert`);
    
    // Seite neu laden, um Änderungen zu übernehmen
    if (window.confirm('Seite neu laden, um die CDN-Strategie anzuwenden?')) {
      window.location.reload();
    }
  };
  
  const checkDuplicateScripts = () => {
    const scripts = document.querySelectorAll('script[src]');
    const scriptSrcs = new Map<string, number>();
    let duplicates = 0;
    
    scripts.forEach(script => {
      const src = script.getAttribute('src') || '';
      // Normalisiere den Pfad (entferne Domain, Query-Parameter)
      const normalizedSrc = src.replace(/^https?:\/\/[^\/]+/, '').split('?')[0];
      
      if (scriptSrcs.has(normalizedSrc)) {
        scriptSrcs.set(normalizedSrc, (scriptSrcs.get(normalizedSrc) || 0) + 1);
        duplicates++;
      } else {
        scriptSrcs.set(normalizedSrc, 1);
      }
    });
    
    if (duplicates > 0) {
      toast.warning(`${duplicates} doppelt geladene Skripte gefunden!`);
      console.warn('Doppelte Skripte:', 
        Array.from(scriptSrcs.entries())
          .filter(([_, count]) => count > 1)
          .map(([src, count]) => `${src} (${count}x)`)
      );
    } else {
      toast.success('Keine doppelten Skripte gefunden!');
    }
  };
  
  return (
    <div className="fixed bottom-4 right-4 bg-white border border-gray-300 p-3 rounded-md shadow-lg z-50 text-sm">
      <h3 className="font-bold mb-2">CDN-Strategie</h3>
      <select 
        value={strategy}
        onChange={handleStrategyChange}
        className="w-full p-1 border border-gray-300 rounded mb-2"
      >
        <option value={CDNSelectionStrategy.DETERMINISTIC}>Deterministisch (konstant)</option>
        <option value={CDNSelectionStrategy.RANDOM}>Zufällig (bei jedem Laden)</option>
      </select>
      <p className="text-xs text-gray-600 mb-2">
        Aktuelle Strategie: {strategy === CDNSelectionStrategy.RANDOM ? 'Zufällig' : 'Deterministisch'}
      </p>
      <div className="text-xs text-gray-600 mb-2">
        Geladene Skripte: {loadedScripts}
      </div>
      <Button 
        variant="outline"
        size="sm"
        className="w-full text-xs"
        onClick={checkDuplicateScripts}
      >
        Doppelte Skripte prüfen
      </Button>
    </div>
  );
};

export default CDNStrategyControl;
