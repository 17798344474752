
/**
 * CDN-Initialisierer
 * 
 * Initialisiert die CDN-Strategie beim Laden der Seite
 */

import { CDNSelectionStrategy } from './cdnConfig';
import { loadScriptWithCdn } from './modules/scriptHandler';
import { initializeCDNCore } from './modules/cdnInitCore';
import { patchRuntimeModuleLoading } from './modules/script/runtimeModulePatcher';
import { saveCDNStrategy } from './modules/strategyManager';

// Track initialization status globally
let isInitialized = false;

/**
 * Initialisiert die CDN-Strategie basierend auf den Einstellungen oder URL-Parametern
 */
export const initializeCDNStrategy = (): void => {
  // Prevent multiple initializations
  if (isInitialized) {
    console.log('CDN strategy already initialized, skipping duplicate initialization');
    return;
  }
  
  // Mark as initialized
  isInitialized = true;
  console.log('Initializing CDN strategy...'); // Debug
  
  // Run core initialization
  initializeCDNCore();
  
  // Patch runtime module loading for Vite-generated code
  patchRuntimeModuleLoading();
  
  // Patch any dynamically loaded modules that might come later
  setTimeout(patchRuntimeModuleLoading, 1000);  // Try again after a short delay for async loaded modules
  
  // For good measure, try a third time after most content has loaded
  setTimeout(patchRuntimeModuleLoading, 3000);
  
  // Final check that page is properly loading
  setTimeout(() => {
    const loadingSpinner = document.querySelector('.loading-spinner');
    if (loadingSpinner && loadingSpinner.parentElement) {
      console.log('Removing lingering loading spinner...');
      loadingSpinner.parentElement.style.display = 'none';
    }
    
    // Force render if root is still not visible
    const root = document.getElementById('root');
    if (root && (!root.children.length || root.children.length === 1 && root.children[0].classList.contains('loading-spinner'))) {
      console.log('Root element appears empty, forcing re-render...');
      const event = new Event('DOMContentLoaded');
      document.dispatchEvent(event);
    }
  }, 5000);
  
  console.log('CDN strategy initialization complete');
};

// Export necessary functions and constants for external use
export { saveCDNStrategy, loadScriptWithCdn };

// Re-export CDNSelectionStrategy for convenience
export { CDNSelectionStrategy };
