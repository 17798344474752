
/**
 * Script Deduplication
 * 
 * Handles removing duplicate script tags from the document
 */

import { markAssetAsLoaded } from '../assetTracker';
import { normalizeUrlForTracking } from '../assetTracker';

/**
 * Removes duplicate script tags from the document
 */
export const removeDuplicateScripts = (): void => {
  // Track script sources
  const scriptSources = new Map<string, HTMLScriptElement>();
  const scripts = document.querySelectorAll('script[src]');
  
  // First pass - build a map of normalized URLs to their script elements
  scripts.forEach(script => {
    const scriptElement = script as HTMLScriptElement;
    const src = scriptElement.getAttribute('src') || '';
    
    // Skip inline scripts
    if (!src) return;
    
    // Normalize the src for comparison
    const normalizedSrc = normalizeUrlForTracking(src);
    
    if (!scriptSources.has(normalizedSrc)) {
      scriptSources.set(normalizedSrc, scriptElement);
    } else {
      // Handle duplicates
      const existingScript = scriptSources.get(normalizedSrc)!;
      
      // Examine if one is CDN and one is main domain
      const isCdnScript = src.includes('cdn.nex2go-it.de');
      const isExistingCdnScript = existingScript.src.includes('cdn.nex2go-it.de');
      
      // Prefer CDN over non-CDN
      if (isCdnScript && !isExistingCdnScript) {
        // Remove the non-CDN version and keep the CDN version
        console.log(`Removing duplicate non-CDN script: ${existingScript.src} (keeping CDN: ${src})`);
        existingScript.parentNode?.removeChild(existingScript);
        scriptSources.set(normalizedSrc, scriptElement);
        markAssetAsLoaded(src, 'kept-cdn-removed-main');
      } else if (!isCdnScript && isExistingCdnScript) {
        // Keep the existing CDN version, remove the non-CDN duplicate
        console.log(`Removing duplicate non-CDN script: ${src} (keeping CDN: ${existingScript.src})`);
        scriptElement.parentNode?.removeChild(scriptElement);
        markAssetAsLoaded(src, 'kept-cdn-removed-duplicate');
      } else if (!scriptElement.hasAttribute('data-cdn-loaded') && existingScript.hasAttribute('data-cdn-loaded')) {
        // If both are CDN or both are non-CDN, prefer the one with data-cdn-loaded
        console.log(`Removing non-marked script: ${src}`);
        scriptElement.parentNode?.removeChild(scriptElement);
        markAssetAsLoaded(src, 'kept-marked-removed-unmarked');
      } else if (scriptElement.hasAttribute('data-cdn-loaded') && !existingScript.hasAttribute('data-cdn-loaded')) {
        console.log(`Removing non-marked script: ${existingScript.src}`);
        existingScript.parentNode?.removeChild(existingScript);
        scriptSources.set(normalizedSrc, scriptElement);
        markAssetAsLoaded(src, 'kept-marked-removed-unmarked');
      } else {
        // Both have data-cdn-loaded or both don't, keep the first one
        console.log(`Removing second script: ${scriptElement.src}`);
        scriptElement.parentNode?.removeChild(scriptElement);
        markAssetAsLoaded(src, 'kept-first-removed-second');
      }
    }
  });
  
  // Second pass - check for any new scripts added during processing
  const secondPassScripts = document.querySelectorAll('script[src]:not([data-processed])');
  secondPassScripts.forEach(script => {
    const scriptElement = script as HTMLScriptElement;
    scriptElement.setAttribute('data-processed', 'true');
    
    const src = scriptElement.getAttribute('src') || '';
    if (!src) return;
    
    const normalizedSrc = normalizeUrlForTracking(src);
    if (scriptSources.has(normalizedSrc) && scriptSources.get(normalizedSrc) !== scriptElement) {
      // This is a duplicate added after our first pass
      console.log(`Removing late-added duplicate script: ${src}`);
      scriptElement.parentNode?.removeChild(scriptElement);
      markAssetAsLoaded(src, 'second-pass-removal');
    } else {
      scriptSources.set(normalizedSrc, scriptElement);
      markAssetAsLoaded(src, 'second-pass-kept');
    }
  });
};
