
/**
 * Dynamic Import Patcher
 * 
 * Handles patching of dynamic imports for CDN support
 */

import { PRIMARY_CDN } from '../../cdnConfig';

/**
 * Patches dynamic imports to use CDN URLs
 */
export const patchDynamicImports = (): void => {
  // Handle dynamic imports in browsers that support it
  if (typeof window !== 'undefined') {
    // Store original import function
    const originalImport = window.Function.prototype.constructor;
    
    try {
      // Override the Function constructor to intercept dynamic imports
      window.Function.prototype.constructor = function(body: string) {
        if (typeof body === 'string') {
          // Replace various import patterns
          if (body.includes('import(') || body.includes('import (')) {
            // Replace standard dynamic imports
            body = body.replace(
              /import\(\s*['"]([^'"]+)['"]\s*\)/g,
              (match, importPath) => {
                if (importPath.startsWith('/')) {
                  return `import("${PRIMARY_CDN}${importPath}")`;
                }
                return match;
              }
            );
          }
          
          // Handle Vite's __vite__mapDeps pattern
          if (body.includes('__vite__mapDeps')) {
            body = body.replace(
              /(const\s+__vite__mapDeps\s*=\s*\([^)]*\)\s*=>\s*[^.]*\.map\([^)]*\)\s*,\s*d\s*=\s*\([^)]*\)\s*\[\s*(['"])(.+?)(['"])\s*\])/g,
              (match, prefix, quote1, paths, quote2) => {
                // Replace asset paths with CDN URLs
                return match.replace(/(['"])assets\/js\/([^'"]+)\.js\1/g, `$1${PRIMARY_CDN}/assets/js/$2.js$1`);
              }
            );
          }
          
          // Handle regular imports from vendor or other JS files
          if (body.includes('from "./vendor.js"') || body.includes('from "./js/vendor.js"')) {
            body = body.replace(
              /from\s+(['"])\.\/(js\/)?vendor\.js\1/g, 
              `from $1${PRIMARY_CDN}/assets/js/vendor.js$1`
            );
          }
          
          // Handle any JS file import
          body = body.replace(
            /from\s+(['"])\.\/(js\/)?([^'"]+)\.js\1/g, 
            `from $1${PRIMARY_CDN}/assets/js/$3.js$1`
          );
        }
        
        return originalImport.call(this, body);
      };
    } catch (e) {
      console.warn('Could not patch dynamic imports:', e);
    }
  }
};

/**
 * Patch import.meta.url handling
 */
export const patchImportMeta = (): void => {
  try {
    // Check if import.meta exists and is an object
    if (typeof import.meta === 'object' && import.meta !== null) {
      const originalMeta = Object.getOwnPropertyDescriptor(import.meta, 'url');
      // Fix for TypeScript error: Check if originalMeta and originalMeta.get exist
      if (originalMeta && typeof originalMeta.get === 'function') {
        Object.defineProperty(import.meta, 'url', {
          get: function() {
            // Use a bound function to preserve 'this' context
            const getUrlValue = originalMeta.get?.bind(this) || (() => "");
            const url = getUrlValue();
            if (url && typeof url === 'string' && url.startsWith(window.location.origin)) {
              return url.replace(window.location.origin, PRIMARY_CDN);
            }
            return url;
          }
        });
      }
    }
  } catch (e) {
    console.warn('Could not patch import.meta.url:', e);
  }
};
