
/**
 * Sets the HTTP status code for the current page response
 * This is primarily for the error pages to set the correct HTTP status code
 * 
 * @param code The HTTP status code to set
 */
export function setHttpStatusCode(code: number): void {
  // For MPA mode, we can use document.title to indicate the status
  if (!document.title.includes(`${code}`)) {
    // Only modify if the code isn't already in the title
    document.title = `${code} - ${document.title}`;
  }
  
  // Set status code in meta tags for SEO and crawlers
  const existingStatusMeta = document.querySelector('meta[http-equiv="status"]');
  if (existingStatusMeta) {
    existingStatusMeta.setAttribute('content', code.toString());
  } else {
    const statusMeta = document.createElement('meta');
    statusMeta.setAttribute('http-equiv', 'status');
    statusMeta.setAttribute('content', code.toString());
    document.head.appendChild(statusMeta);
  }
  
  // Add X-Status-Code custom header which some crawlers recognize
  const xStatusMeta = document.querySelector('meta[http-equiv="X-Status-Code"]');
  if (xStatusMeta) {
    xStatusMeta.setAttribute('content', code.toString());
  } else {
    const xStatusCodeMeta = document.createElement('meta');
    xStatusCodeMeta.setAttribute('http-equiv', 'X-Status-Code');
    xStatusCodeMeta.setAttribute('content', code.toString());
    document.head.appendChild(xStatusCodeMeta);
  }
  
  // Log that we're setting the status code via Netlify config
  console.log(`HTTP Status Code: ${code} (This page should serve with HTTP ${code} status via Netlify configuration)`);
  
  // Add a custom HTML attribute to the root element for easy debugging
  document.documentElement.setAttribute('data-http-status', code.toString());
  
  // Dispatch a custom event for analytics or custom handling
  window.dispatchEvent(new CustomEvent('http-status-change', { 
    detail: { statusCode: code } 
  }));
}
