
import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { analyzeDuplicateAssets, getLoadedAssets, getAssetLoadLog } from '../../utils/asset/modules/assetTracker';
import { toast } from 'sonner';

/**
 * Komponente zur Analyse und Anzeige doppelt geladener Assets
 * Nur im Entwicklungs- oder Debug-Modus sichtbar
 */
export const AssetLoadingDebugger: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [duplicateStats, setDuplicateStats] = useState<{
    duplicateCount: number;
    duplicates: Array<{normalizedUrl: string; instances: string[]}>;
  }>({ duplicateCount: 0, duplicates: [] });
  
  useEffect(() => {
    // Im Entwicklungsmodus oder mit Debug-Flag anzeigen
    const isDev = process.env.NODE_ENV === 'development';
    const isDebug = new URLSearchParams(window.location.search).get('debug') === 'true' ||
                   localStorage.getItem('debug') === 'true';
    
    setIsVisible(isDev || isDebug);
    
    // Analysiere nach kurzer Verzögerung, um die Seite laden zu lassen
    if (isDev || isDebug) {
      setTimeout(() => {
        const analysis = analyzeDuplicateAssets();
        setDuplicateStats(analysis);
        
        if (analysis.duplicateCount > 0) {
          toast.warning(`${analysis.duplicateCount} doppelt geladene Assets gefunden!`);
        }
      }, 2000);
    }
  }, []);
  
  if (!isVisible) return null;
  
  const handleAnalyze = () => {
    const analysis = analyzeDuplicateAssets();
    setDuplicateStats(analysis);
    
    if (analysis.duplicateCount > 0) {
      toast.warning(`${analysis.duplicateCount} doppelt geladene Assets gefunden!`);
    } else {
      toast.success('Keine doppelt geladenen Assets gefunden!');
    }
  };
  
  const downloadAssetReport = () => {
    const assetLoadLog = getAssetLoadLog();
    const loadedAssets = getLoadedAssets();
    
    const report = {
      timestamp: new Date().toISOString(),
      pageUrl: window.location.href,
      duplicateAnalysis: duplicateStats,
      loadedAssets,
      assetLoadLog
    };
    
    const blob = new Blob([JSON.stringify(report, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'asset-report.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    
    toast.success('Asset-Report heruntergeladen');
  };
  
  return (
    <div className="fixed bottom-4 left-4 bg-white border border-gray-300 p-3 rounded-md shadow-lg z-50 text-sm max-w-sm">
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-bold">Asset-Debug</h3>
        <button 
          className="text-xs text-gray-500"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Einklappen' : 'Ausklappen'}
        </button>
      </div>
      
      {isExpanded ? (
        <div className="space-y-3">
          <div className="text-xs">
            <p className="font-semibold mb-1">Doppelt geladene Assets: {duplicateStats.duplicateCount}</p>
            <div className="max-h-40 overflow-y-auto text-xs bg-gray-50 p-1 rounded">
              {duplicateStats.duplicates.map((dup, idx) => (
                <div key={idx} className="mb-2 pb-2 border-b border-gray-200 last:border-0">
                  <div className="font-mono text-red-500">...{dup.normalizedUrl.slice(-30)}</div>
                  <ul className="ml-2">
                    {dup.instances.map((instance, i) => (
                      <li key={i} className="text-gray-600 truncate" title={instance}>
                        {instance.length > 40 ? '...' + instance.slice(-40) : instance}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {duplicateStats.duplicateCount === 0 && (
                <p className="text-green-600">Keine Duplikate gefunden!</p>
              )}
            </div>
          </div>
          
          <div className="flex flex-col space-y-2">
            <Button 
              variant="outline" 
              size="sm" 
              className="text-xs" 
              onClick={handleAnalyze}
            >
              Assets neu analysieren
            </Button>
            <Button 
              variant="outline" 
              size="sm" 
              className="text-xs" 
              onClick={downloadAssetReport}
            >
              Report herunterladen
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="text-xs text-gray-600 mb-2">
            Doppelt geladene Assets: <span className={duplicateStats.duplicateCount > 0 ? "text-red-500 font-bold" : "text-green-500"}>{duplicateStats.duplicateCount}</span>
          </div>
          <Button 
            variant="outline" 
            size="sm" 
            className="text-xs" 
            onClick={() => setIsExpanded(true)}
          >
            Details anzeigen
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssetLoadingDebugger;
