
import { Container } from "@/components/ui/Container";
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import { FileText, Key, Scale, Info, Shield, AlarmClock, Bookmark } from "lucide-react";
import { useEffect } from "react";

export default function AGB() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Navbar />
      <main className="flex-grow pt-24 pb-16">
        <Container>
          <div className="bg-card dark:bg-nex-900 shadow-md rounded-lg p-8 border border-border animate-fade-in">
            <h1 className="text-2xl md:text-3xl font-bold mb-8 pb-4 border-b border-border dark:border-nex-800 flex items-center text-red-800 dark:text-red-600">
              <FileText className="mr-3 h-6 w-6" />
              Allgemeine Geschäftsbedingungen (AGB)
            </h1>
            
            <section className="mb-8 animate-slide-up [animation-delay:100ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Bookmark className="mr-2 h-5 w-5" />
                1. Geltungsbereich
              </h2>
              <p className="mb-4">1.1 Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Verträge zwischen NEX2GO solution center, Waldemar Wetzel (nachfolgend "NEX2GO") und seinen Kunden (nachfolgend "Auftraggeber").</p>
              <p className="mb-4">1.2 Abweichende Bedingungen des Auftraggebers werden nicht anerkannt, es sei denn, NEX2GO stimmt ihrer Geltung ausdrücklich schriftlich zu.</p>
              <p className="mb-4">1.3 Diese AGB gelten auch für alle zukünftigen Geschäfte mit dem Auftraggeber, ohne dass es eines erneuten Hinweises auf die AGB bedarf.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:200ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                2. Vertragsschluss
              </h2>
              <p className="mb-4">2.1 Angebote von NEX2GO sind freibleibend und unverbindlich, sofern sie nicht ausdrücklich als verbindlich gekennzeichnet sind.</p>
              <p className="mb-4">2.2 Ein Vertrag kommt erst durch die schriftliche Auftragsbestätigung von NEX2GO oder durch Beginn der Auftragsausführung zustande. Der Schriftform genügt auch die Übermittlung per E-Mail oder Fax.</p>
              <p className="mb-4">2.3 Mündliche Zusagen von NEX2GO vor Abschluss dieses Vertrages sind rechtlich unverbindlich. Mündliche Abreden werden durch den schriftlichen Vertrag ersetzt, sofern sich nicht ausdrücklich aus ihnen ergibt, dass sie verbindlich fortgelten sollen.</p>
            </section>

            <div className="mb-8 bg-muted dark:bg-nex-800 p-6 rounded-lg animate-slide-up [animation-delay:300ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                3. Leistungsumfang
              </h2>
              <p className="mb-4">3.1 Der Umfang der zu erbringenden Leistungen ergibt sich aus der Leistungsbeschreibung in der Auftragsbestätigung oder dem Angebot sowie etwaigen Anlagen dazu.</p>
              <p className="mb-4">3.2 NEX2GO ist berechtigt, Leistungen durch Dritte erbringen zu lassen. Die Verantwortung für die Leistungserbringung bleibt in diesem Fall bei NEX2GO.</p>
              <p className="mb-4">3.3 Änderungen des Leistungsumfangs bedürfen der Schriftform. NEX2GO kann Änderungen des Leistungsumfangs verlangen, die zur Ausführung der Leistung notwendig sind, sofern diese dem Auftraggeber zumutbar sind.</p>
            </div>

            <section className="mb-8 animate-slide-up [animation-delay:400ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                4. Mitwirkungspflichten des Auftraggebers
              </h2>
              <p className="mb-4">4.1 Der Auftraggeber stellt NEX2GO alle für die Durchführung des Projekts erforderlichen Informationen, Materialien und Unterlagen rechtzeitig zur Verfügung und gewährleistet deren Richtigkeit und Vollständigkeit.</p>
              <p className="mb-4">4.2 Der Auftraggeber benennt einen Ansprechpartner, der für die Abstimmung der Leistungserbringung verantwortlich ist und verbindliche Entscheidungen treffen kann.</p>
              <p className="mb-4">4.3 Mitwirkungshandlungen nimmt der Auftraggeber auf seine Kosten vor. Verzögerungen durch mangelnde Mitwirkung des Auftraggebers gehen nicht zu Lasten von NEX2GO.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:500ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Scale className="mr-2 h-5 w-5" />
                5. Vergütung und Zahlungsbedingungen
              </h2>
              <p className="mb-4">5.1 Es gelten die im Angebot oder in der Auftragsbestätigung genannten Preise. Alle Preise verstehen sich zuzüglich der gesetzlichen Umsatzsteuer, sofern nicht ausdrücklich als Bruttopreise gekennzeichnet.</p>
              <p className="mb-4">5.2 Sofern nicht anders vereinbart, sind Rechnungen innerhalb von 14 Tagen nach Rechnungsstellung ohne Abzug zahlbar.</p>
              <p className="mb-4">5.3 Bei Zahlungsverzug ist NEX2GO berechtigt, Verzugszinsen in Höhe von 9 Prozentpunkten über dem Basiszinssatz zu berechnen. Die Geltendmachung eines weiteren Verzugsschadens bleibt vorbehalten.</p>
              <p className="mb-4">5.4 Aufrechnungsrechte stehen dem Auftraggeber nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt, unbestritten oder von NEX2GO anerkannt sind.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:600ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <AlarmClock className="mr-2 h-5 w-5" />
                6. Lieferzeiten und Fristen
              </h2>
              <p className="mb-4">6.1 Termine und Fristen sind unverbindlich, sofern sie nicht ausdrücklich als verbindlich vereinbart wurden.</p>
              <p className="mb-4">6.2 Liefer- und Leistungsverzögerungen aufgrund höherer Gewalt oder aufgrund von Ereignissen, die NEX2GO die Leistung wesentlich erschweren oder unmöglich machen, hat NEX2GO nicht zu vertreten. In diesem Fall ist NEX2GO berechtigt, die Leistung um die Dauer der Behinderung zuzüglich einer angemessenen Anlaufzeit hinauszuschieben.</p>
              <p className="mb-4">6.3 Kommt der Auftraggeber seinen Mitwirkungspflichten nicht nach und verzögert sich die Leistungserbringung dadurch, verlängern sich die Fristen entsprechend.</p>
            </section>

            <div className="mb-8 bg-muted dark:bg-nex-800 p-6 rounded-lg animate-slide-up [animation-delay:700ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Key className="mr-2 h-5 w-5" />
                7. Nutzungsrechte
              </h2>
              <p className="mb-4">7.1 NEX2GO überträgt dem Auftraggeber mit vollständiger Bezahlung der vereinbarten Vergütung die für den jeweiligen Vertragszweck erforderlichen Nutzungsrechte an den erstellten Arbeitsergebnissen.</p>
              <p className="mb-4">7.2 Soweit nicht anders vereinbart, räumt NEX2GO dem Auftraggeber ein einfaches, zeitlich und räumlich unbeschränktes Nutzungsrecht ein.</p>
              <p className="mb-4">7.3 Eine Übertragung der Quelldateien erfolgt nur, wenn dies ausdrücklich vereinbart wurde. Soweit die Übertragung von Quellcode vereinbart ist, behält NEX2GO das Recht, diesen auch für andere Projekte zu verwenden, sofern nicht ausdrücklich Exklusivität vereinbart wurde.</p>
              <p className="mb-4">7.4 Der Auftraggeber hat keinen Anspruch auf Herausgabe der Quelldateien oder der Entwicklungsdokumentation.</p>
            </div>

            <section className="mb-8 animate-slide-up [animation-delay:800ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                8. Gewährleistung und Haftung
              </h2>
              <p className="mb-4">8.1 NEX2GO haftet für Schäden, die auf Vorsatz oder grober Fahrlässigkeit beruhen, unbeschränkt.</p>
              <p className="mb-4">8.2 Bei leichter Fahrlässigkeit haftet NEX2GO nur bei Verletzung einer wesentlichen Vertragspflicht und nur in Höhe des vorhersehbaren, typischerweise eintretenden Schadens. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Auftraggeber regelmäßig vertraut.</p>
              <p className="mb-4">8.3 Die Haftung für Datenverlust ist auf den typischen Wiederherstellungsaufwand beschränkt, der bei regelmäßiger Datensicherung eingetreten wäre.</p>
              <p className="mb-4">8.4 Die vorstehenden Haftungsbeschränkungen gelten nicht bei der Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln.</p>
              <p className="mb-4">8.5 Die Gewährleistungsfrist beträgt 12 Monate ab Abnahme der Leistung, sofern keine abweichende Vereinbarung getroffen wurde.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:900ms]">
              <h2 className="flex items-center text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                <Shield className="mr-2 h-5 w-5" />
                9. Vertraulichkeit und Datenschutz
              </h2>
              <p className="mb-4">9.1 Die Vertragsparteien verpflichten sich, alle im Rahmen der Vertragsanbahnung und -durchführung erlangten Kenntnisse von vertraulichen Informationen und Betriebsgeheimnissen vertraulich zu behandeln und nur für Zwecke der Vertragserfüllung zu verwenden.</p>
              <p className="mb-4">9.2 NEX2GO verarbeitet personenbezogene Daten des Auftraggebers unter Einhaltung der geltenden datenschutzrechtlichen Bestimmungen, insbesondere der EU-Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG).</p>
              <p className="mb-4">9.3 Weitere Informationen zur Datenverarbeitung finden Sie in unserer Datenschutzerklärung auf unserer Website.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:1000ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                10. Widerrufsrecht für Verbraucher
              </h2>
              <p className="mb-4">10.1 Ist der Auftraggeber Verbraucher im Sinne des § 13 BGB, steht ihm ein gesetzliches Widerrufsrecht zu.</p>
              <p className="mb-4">10.2 Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.</p>
              <p className="mb-4">10.3 Um das Widerrufsrecht auszuüben, muss der Auftraggeber NEX2GO mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über seinen Entschluss, diesen Vertrag zu widerrufen, informieren.</p>
              <p className="mb-4">10.4 Das Widerrufsrecht erlischt bei Verträgen zur Lieferung von digitalen Inhalten, wenn NEX2GO mit der Ausführung des Vertrags begonnen hat, nachdem der Verbraucher ausdrücklich zugestimmt hat, dass NEX2GO mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt und der Verbraucher seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung sein Widerrufsrecht verliert.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:1100ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                11. Streitschlichtung
              </h2>
              <p className="mb-4">11.1 Die EU-Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die unter <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer" className="text-red-800 dark:text-red-600 hover:underline">https://ec.europa.eu/consumers/odr</a> aufrufbar ist.</p>
              <p className="mb-4">11.2 NEX2GO ist nicht bereit und nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </section>

            <section className="mb-8 animate-slide-up [animation-delay:1200ms]">
              <h2 className="text-xl font-semibold mb-4 text-red-800 dark:text-red-600">
                12. Schlussbestimmungen
              </h2>
              <p className="mb-4">12.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Ist der Auftraggeber Verbraucher, gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.</p>
              <p className="mb-4">12.2 Erfüllungsort und ausschließlicher Gerichtsstand für alle Streitigkeiten ist, soweit gesetzlich zulässig, der Sitz von NEX2GO. NEX2GO ist jedoch berechtigt, am allgemeinen Gerichtsstand des Auftraggebers zu klagen.</p>
              <p className="mb-4">12.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht. Die Vertragsparteien verpflichten sich, unwirksame Bestimmungen durch neue Bestimmungen zu ersetzen, die der in den unwirksamen Bestimmungen enthaltenen Regelungen in rechtlich zulässiger Weise gerecht werden.</p>
            </section>

            <div className="mt-10 text-sm text-muted-foreground">
              <p>Stand: Juni 2024</p>
              <p>NEX2GO solution center | Waldemar Wetzel | Hochfeldring 92/2 | 76549 Hügelsheim</p>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
