
/**
 * CDN Initialization Core
 * 
 * Core initialization logic for CDN functionality
 */

import { PRIMARY_CDN } from '../cdnConfig';
import { initializeStrategy } from './strategyManager';
import { fixModulePreloadResources, processSrcAttributes } from './domProcessor';
import { patchViteModules } from './viteModuleHandler';
import { preventMainDomainLoading } from './domainConflictHandler';
import { initializeScriptPatchers, observeDynamicScriptAdditions } from './scriptHandler';
import { analyzeDuplicateAssets } from './assetTracker';

// Flag to track initialization status
let isInitialized = false;

// Store the original location to prevent double loading with different domains
const originalDomain = window.location.hostname;

/**
 * Core CDN initialization function
 */
export const initializeCDNCore = (): void => {
  // Prevent multiple initializations
  if (isInitialized) {
    console.log('CDN core already initialized, skipping');
    return;
  }
  
  // Mark as initialized first to prevent race conditions
  isInitialized = true;
  console.log('Initializing CDN core functionality...');
  
  // Check if we're on the original domain or CDN domain to prevent duplicates
  const currentDomain = window.location.hostname;
  if (currentDomain !== originalDomain && originalDomain !== 'localhost' && originalDomain !== '') {
    console.log(`Detected possible duplicate loading: ${currentDomain} vs original ${originalDomain}`);
    // If we detect we're loading from a different domain than the original page load,
    // we might be in a duplicate loading situation - don't initialize again
    if (document.querySelector('meta[name="main-domain-initialized"]')) {
      console.log('Main domain already initialized, skipping CDN initialization');
      return;
    }
  }
  
  // Mark the page as initialized from the main domain
  const metaTag = document.createElement('meta');
  metaTag.name = 'main-domain-initialized';
  metaTag.content = 'true';
  document.head.appendChild(metaTag);
  
  // Make PRIMARY_CDN available globally to help with debugging and other scripts
  try {
    if (typeof window !== 'undefined') {
      (window as any).PRIMARY_CDN = PRIMARY_CDN;
      console.log(`CDN url set globally: ${PRIMARY_CDN}`);
    }
  } catch (e) {
    console.error('Error setting global CDN URL:', e);
  }
  
  // Prevent main domain scripts from loading when on CDN domain - do this first!
  if (currentDomain.includes('cdn.nex2go-it.de')) {
    preventMainDomainLoading();
  }
  
  // Initialize the CDN strategy based on URL parameters or localStorage
  initializeStrategy();
  
  // Initialize all script patchers - this should come before processing DOM elements
  initializeScriptPatchers();
  
  // Fix for modulepreload resources
  fixModulePreloadResources();
  
  // Process all src attributes to ensure CDN usage
  processSrcAttributes();
  
  // Patch Vite's module loading system
  patchViteModules();
  
  // Start observing for dynamic script additions
  observeDynamicScriptAdditions();
  
  // Force display of the app if it's still showing loading animation after a delay
  setTimeout(() => {
    const loadingEl = document.querySelector('#root > div');
    if (loadingEl && loadingEl.classList.contains('loading-spinner')) {
      console.log('Forcing removal of initial loading state...');
      loadingEl.remove();
    }
    
    // Fix for rendering issues - force document ready event if app isn't visible
    const app = document.querySelector('#root > div:not(.loading-spinner)');
    if (!app) {
      console.log('App not rendered, triggering DOMContentLoaded event...');
      const event = new Event('DOMContentLoaded');
      document.dispatchEvent(event);
    }
  }, 3000);
  
  // Analyze duplicates after a short delay
  setTimeout(() => {
    const duplicateAnalysis = analyzeDuplicateAssets();
    if (duplicateAnalysis.duplicateCount > 0) {
      console.warn(`Found ${duplicateAnalysis.duplicateCount} duplicate assets after initialization`);
    }
  }, 3000);
  
  console.log('CDN core initialization complete');
};
