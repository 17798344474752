
/**
 * Asset Tracker Module
 * 
 * Verfolgt geladene Assets, um Duplikate zu vermeiden
 */

// Set für bereits geladene Assets (normalisierte URLs)
const loadedAssets = new Set<string>();

// Protokoll für Debug-Zwecke
const assetLoadLog: Array<{url: string, time: number, source: string}> = [];

/**
 * Normalisiert eine URL für Tracking-Zwecke
 * - Entfernt Protokoll, Domain, Query-Parameter und Hash
 * - Normalisiert Pfadseparatoren
 */
export const normalizeUrlForTracking = (url: string): string => {
  if (!url) return '';
  
  // Entferne Protokoll und Domain
  let normalized = url.replace(/^(https?:)?\/\/([^/]+)/, '');
  
  // Entferne Query-Parameter und Hash
  normalized = normalized.split('?')[0].split('#')[0];
  
  // Stelle sicher, dass alle Pfade mit / beginnen
  if (!normalized.startsWith('/')) {
    normalized = '/' + normalized;
  }
  
  // Entferne Vite-Hash-Suffixe (z.B. -a1b2c3d4)
  normalized = normalized.replace(/-[a-f0-9]{8}(\.[^.]+)$/, '$1');
  
  return normalized;
};

/**
 * Prüft, ob ein Asset bereits geladen wurde
 */
export const isAssetLoaded = (url: string): boolean => {
  const normalizedUrl = normalizeUrlForTracking(url);
  return loadedAssets.has(normalizedUrl);
};

/**
 * Markiert ein Asset als geladen
 */
export const markAssetAsLoaded = (url: string, source = 'unknown'): void => {
  const normalizedUrl = normalizeUrlForTracking(url);
  
  // Vermeide Duplikate im Log
  if (!loadedAssets.has(normalizedUrl)) {
    loadedAssets.add(normalizedUrl);
    
    // Protokolliere für Debug-Zwecke
    assetLoadLog.push({
      url: normalizedUrl,
      time: Date.now(),
      source
    });
  }
};

/**
 * Gibt eine Liste aller geladenen Assets zurück
 */
export const getLoadedAssets = (): string[] => {
  return Array.from(loadedAssets);
};

/**
 * Gibt das Asset-Load-Log zurück
 */
export const getAssetLoadLog = () => {
  return [...assetLoadLog];
};

/**
 * Gibt eine Analyse der doppelt geladenen Assets zurück
 */
export const analyzeDuplicateAssets = (): {
  duplicateCount: number;
  duplicates: Array<{normalizedUrl: string, instances: string[]}>
} => {
  // Gruppiere Assets nach normalisierter URL
  const urlGroups = new Map<string, string[]>();
  
  // Sammle alle src-Attribute von Assets
  const images = document.querySelectorAll('img[src]');
  const scripts = document.querySelectorAll('script[src]');
  const links = document.querySelectorAll('link[href]');
  
  // Verarbeite Bilder
  images.forEach(img => {
    const src = (img as HTMLImageElement).src;
    const normalized = normalizeUrlForTracking(src);
    
    if (!urlGroups.has(normalized)) {
      urlGroups.set(normalized, []);
    }
    urlGroups.get(normalized)!.push(src);
  });
  
  // Verarbeite Scripts
  scripts.forEach(script => {
    const src = script.getAttribute('src');
    if (src) {
      const normalized = normalizeUrlForTracking(src);
      
      if (!urlGroups.has(normalized)) {
        urlGroups.set(normalized, []);
      }
      urlGroups.get(normalized)!.push(src);
    }
  });
  
  // Verarbeite Links (CSS, Preload, etc.)
  links.forEach(link => {
    const href = link.getAttribute('href');
    if (href) {
      const normalized = normalizeUrlForTracking(href);
      
      if (!urlGroups.has(normalized)) {
        urlGroups.set(normalized, []);
      }
      urlGroups.get(normalized)!.push(href);
    }
  });
  
  // Finde Duplikate (mehr als eine URL pro normalisiertem Pfad)
  const duplicates = Array.from(urlGroups.entries())
    .filter(([_, urls]) => urls.length > 1)
    .map(([normalizedUrl, instances]) => ({ normalizedUrl, instances }));
  
  return {
    duplicateCount: duplicates.length,
    duplicates
  };
};
