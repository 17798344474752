
import React, { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { navLinks } from "./NavLinks";
import { MobileNavLink } from "./MobileNavLink";

interface MobileMenuProps {
  isOpen: boolean;
  isHomePage: boolean;
  onClose: () => void;
}

export function MobileMenu({ isOpen, isHomePage, onClose }: MobileMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);

  // Schließe das Menü, wenn außerhalb geklickt wird
  useEffect(() => {
    if (!isOpen) return;
    
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    // Verhindern des Scrollens im Hintergrund wenn Menü offen ist
    document.body.style.overflow = "hidden";
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="md:hidden fixed inset-0 z-40 bg-black/60 backdrop-blur-sm">
      <div 
        ref={menuRef}
        className="absolute right-0 top-0 h-full w-4/5 max-w-xs bg-white shadow-xl transform animate-slide-in"
        style={{ animationDuration: '0.25s' }}
      >
        <div className="p-4 overflow-y-auto h-full flex flex-col">
          <ul className="flex flex-col space-y-6 py-8 flex-1">
            {navLinks.map((link) => (
              <li key={link.name}>
                <MobileNavLink 
                  name={link.name}
                  href={link.href}
                  isHomePage={isHomePage}
                  onClick={onClose}
                />
              </li>
            ))}
          </ul>
          {isHomePage ? (
            <Button 
              className="w-full mt-4 bg-red-800 hover:bg-red-700 text-white transition-all duration-300 py-6 text-base"
              onClick={() => {
                window.location.href="#contact";
                onClose();
              }}
            >
              Kontakt aufnehmen
            </Button>
          ) : (
            <a href="/#contact" className="w-full">
              <Button 
                className="w-full mt-4 bg-red-800 hover:bg-red-700 text-white transition-all duration-300 py-6 text-base"
                onClick={onClose}
              >
                Kontakt aufnehmen
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
