
import React, { useEffect } from "react";
import ErrorPage from "@/components/error/ErrorPage";
import { FileQuestion } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { setHttpStatusCode } from "@/utils/httpStatusCode";

const NotFound = () => {
  useEffect(() => {
    // Set the HTTP status code
    setHttpStatusCode(404);
    
    // Log the 404 error
    console.error(
      "404 Error: User attempted to access non-existent route:",
      window.location.pathname
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>404 - Seite nicht gefunden | NEX2GO</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta http-equiv="status" content="404" />
      </Helmet>
      <ErrorPage
        code="404"
        title="Seite nicht gefunden"
        description="Die von Ihnen gesuchte Seite konnte nicht gefunden werden. Bitte überprüfen Sie die URL oder kehren Sie zur Startseite zurück."
        icon={
          <div className="h-24 w-24 rounded-full bg-red-50 dark:bg-red-900/20 flex items-center justify-center">
            <FileQuestion className="h-12 w-12 text-red-800 dark:text-red-500" />
          </div>
        }
      />
    </>
  );
};

export default NotFound;
