
/**
 * Runtime Module Patcher
 * 
 * Handles patching of runtime modules for CDN support
 */

import { PRIMARY_CDN } from '../../cdnConfig';
import { markAssetAsLoaded } from '../assetTracker';

/**
 * Patch runtime module loading for Vite-generated code
 */
export const patchRuntimeModuleLoading = (): void => {
  if (typeof window !== 'undefined') {
    // Find and modify any global variables set by Vite for module mapping
    const keys = Object.keys(window);
    for (const key of keys) {
      if (key.startsWith('__vite') || key.includes('__map') || key.includes('Deps')) {
        const value = (window as any)[key];
        if (typeof value === 'function' || (typeof value === 'object' && value !== null)) {
          console.log(`Found potential Vite module mapper: ${key}`);
          
          // If it's a function that maps dependencies
          if (typeof value === 'function' && value.toString().includes('.map(')) {
            try {
              // Wrap the function to intercept its calls
              const originalFn = value;
              (window as any)[key] = function(...args: any[]) {
                // Process the result of the original function
                const result = originalFn.apply(this, args);
                if (Array.isArray(result)) {
                  return result.map(item => {
                    if (typeof item === 'string' && item.includes('/assets/')) {
                      const cdnPath = item.replace('/assets/', `${PRIMARY_CDN}/assets/`);
                      markAssetAsLoaded(cdnPath, 'runtime-module-patch');
                      return cdnPath;
                    }
                    return item;
                  });
                }
                return result;
              };
              console.log(`Patched module mapper function: ${key}`);
            } catch (e) {
              console.warn(`Failed to patch module mapper function ${key}:`, e);
            }
          }
          
          // If it has a property that contains module paths
          if (typeof value === 'object' && value.f && Array.isArray(value.f)) {
            try {
              value.f = value.f.map((path: string) => {
                if (typeof path === 'string' && path.startsWith('assets/')) {
                  const cdnPath = `${PRIMARY_CDN}/${path}`;
                  markAssetAsLoaded(cdnPath, 'runtime-module-f-patch');
                  return cdnPath;
                }
                return path;
              });
              console.log(`Patched module paths in ${key}.f`);
            } catch (e) {
              console.warn(`Failed to patch module paths in ${key}.f:`, e);
            }
          }
        }
      }
    }
    
    // Also patch __vitePreload which is commonly used
    patchVitePreload();
  }
};

/**
 * Patches Vite's module preload handler
 */
const patchVitePreload = (): void => {
  if (window.__vitePreload) {
    const originalPreload = window.__vitePreload;
    window.__vitePreload = function(baseModule: any, deps: any[]) {
      // Replace paths in dependencies with CDN URLs
      if (Array.isArray(deps)) {
        deps = deps.map(dep => {
          if (typeof dep === 'string') {
            // For absolute paths
            if (dep.startsWith('/')) {
              const cdnPath = `${PRIMARY_CDN}${dep}`;
              markAssetAsLoaded(cdnPath, 'vite-preload-patch-abs');
              return cdnPath;
            }
            // For relative paths like ./vendor.js
            if (dep.startsWith('./') && dep.endsWith('.js')) {
              const cdnPath = `${PRIMARY_CDN}/assets/js/${dep.substring(2)}`;
              markAssetAsLoaded(cdnPath, 'vite-preload-patch-rel');
              return cdnPath;
            }
            // For fully qualified CDN URLs, pass through
            if (dep.startsWith('http') && (
              dep.includes('cdn.nex2go-it.de') || 
              dep.includes('cdn1.nex2go-it.de') || 
              dep.includes('cdn2.nex2go-it.de') || 
              dep.includes('cdn3.nex2go-it.de') || 
              dep.includes('cdn4.nex2go-it.de')
            )) {
              markAssetAsLoaded(dep, 'vite-preload-patch-cdn');
              return dep;
            }
          }
          return dep;
        });
      }
      return originalPreload(baseModule, deps);
    };
  }
};

// Export the Vite preload patcher for use in other modules
export { patchVitePreload };
