
import React from "react";

interface MobileNavLinkProps {
  name: string;
  href: string;
  isHomePage: boolean;
  onClick?: () => void;
}

export function MobileNavLink({ name, href, isHomePage, onClick }: MobileNavLinkProps) {
  return (
    <a 
      href={href}
      className="block py-3 px-2 text-gray-800 font-medium text-lg hover:text-red-800 transition-colors duration-200 active:bg-gray-100 rounded-md relative overflow-hidden"
      onClick={onClick}
      role="menuitem"
    >
      <span className="relative z-10">{name}</span>
      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-red-800 transition-all duration-300 group-hover:w-full" />
    </a>
  );
}
