
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'sonner';
import { HelmetProvider } from 'react-helmet-async';

// Components
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ScrollHandler } from './components/layout/ScrollHandler';

// Pages
import Index from './pages/Index';
import AboutUs from './pages/AboutUs';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import AGB from './pages/AGB';
import NotFound from './pages/NotFound';

// Admin Components
import CDNStrategyControl from './components/admin/CDNStrategyControl';
import AssetLoadingDebugger from './components/admin/AssetLoadingDebugger';

// Utilities
import './App.css';

const App = () => {
  const location = useLocation();

  return (
    <HelmetProvider>
      <Toaster richColors position="top-right" />
      <ScrollHandler />
      <Navbar />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/ueber-uns" element={<AboutUs />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/agb" element={<AGB />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      
      {/* Admin/Debug Tools - only shown in dev or with debug param */}
      <CDNStrategyControl />
      <AssetLoadingDebugger />
    </HelmetProvider>
  );
};

export default App;
