
import * as React from 'react';
import { Copyright, FileText, ShieldCheck, ClipboardList } from 'lucide-react';

export function FooterCopyright() {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="border-t border-red-700 dark:border-red-800 mt-8 pt-6 text-sm text-center">
      <p className="flex items-center justify-center gap-1">
        <Copyright className="h-4 w-4" />
        <span>{currentYear} NEX2GO solution center. Alle Rechte vorbehalten.</span>
      </p>
      <div className="mt-2 space-x-4 flex justify-center">
        <a href="/impressum/" className="hover:underline flex items-center gap-1">
          <FileText className="h-3 w-3" />
          <span>Impressum</span>
        </a>
        <a href="/datenschutz/" className="hover:underline flex items-center gap-1">
          <ShieldCheck className="h-3 w-3" />
          <span>Datenschutz</span>
        </a>
        <a href="/agb/" className="hover:underline flex items-center gap-1">
          <ClipboardList className="h-3 w-3" />
          <span>AGB</span>
        </a>
      </div>
    </div>
  );
}
