
import { useEffect } from 'react';

interface MetaTagsProps {
  title?: string;
  description?: string;
}

export function MetaTags({ 
  title = "NEX2GO Solution Center - IT-Lösungen, Webhosting, Digitale Transformation",
  description = "NEX2GO bietet professionelle IT-Lösungen, Webhosting, Domainregistrierung und digitale Transformation für Unternehmen. Maßgeschneiderte Lösungen seit 2005."
}: MetaTagsProps) {
  useEffect(() => {
    // Update document title
    document.title = title;
    
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    }
  }, [title, description]);

  return null;
}
